@font-face {
  font-family: "Trade Gothic";
  src: url("/assets/fonts/fb754dec-aa8f-444c-be48-868464c47ab0.woff") format("woff");
}
@font-face {
  font-family: "Trade Gothic";
  src: url("/assets/fonts/5fdc935e-9e30-442a-bbe9-8d887b858471.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Futura Bold-Condensed";
  src: url("/assets/fonts/e07f45c2-9c42-4c23-ab7a-c161556274ef.woff") format("woff");
}
@font-face {
  font-family: "Futura Extra-Bold-Condensed";
  src: url("/assets/fonts/04733668-9f3f-4f2f-a534-14fb44683f83.woff") format("woff");
}