$size--filter-padding: 1em;
$font-size--facet: $font-size--body;
.gbip-search-filter {
    position: relative;
    min-height: 150px;
    &__header {
        @include gbip-headline($font-size--facet);
    }
    &__item {
        font-size: $font-size--small;
    }
    &__item-toggle {
        display: block;
        margin-left: 1.4em;
        background-image: url('/assets/svg/ui/plus.svg');
        background-position: left center;
        background-size: 7px;
        background-repeat: no-repeat;
        font-size: $font-size--small;
        outline: none;
        padding-left: 15px;
        &.is-active {
            background-image: url('/assets/svg/ui/minus.svg');
        }
    }
    &__cta {
        display: block;
        text-align: right;
        &--toggle {
            background: url('/assets/svg/ui/plus.svg') center right no-repeat;
            background-size: 7px;
            padding-right: calc(#{$size--filter-padding}+7px);
            &.is-open {
                background-image: url('/assets/svg/ui/minus.svg');
            }
        }
        >button {
            font-size: $font-size--small;
            color: $color--dark-gray;
            outline: none;
            text-decoration: underline;
        }
    }
}