$size--badge-height: 1.5em;

.gbip-badge {
  font-size: $font-size--small;
  background-color: $color--red;
  color: $color--white;
  border-radius: $size--badge-height/2;
  padding: 0 0.5em;
  position: absolute;
  top: 10px;
  right: 0;
  transform: translate(-50%,-50%);
  line-height: $size--badge-height;
  display: flex;
  align-items: center;
  justify-content: center;
}