.gbip-search-instructions {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size--body;
  font-family: $ff--body;
  line-height: $line-height--body;
  min-height: 300px;
  background: transparent;
  text-align: center;

  &__start {
    color: $color--dark-gray;
  }

  &__no-results {
    color: $color--near-black;
  }

  &__headline {
    margin-bottom: 20px;
  }

  &__copy {
    margin-bottom: 40px;
  }

  &__cta {
    margin: 0 auto;
  }
}
