$size--sidebar: static-width(2);
.gbip-searchview {
  margin-bottom: 20px;
  display: flex;
  &__searchPanel {
    flex-basis: 223px;
    padding-left: $size--column-gutter;
  }
  &__main {
    flex-grow: 2;
  }
  .filterRow {
    padding-top: 5px;
    padding-right: 0px;
    max-width: 100%;
  }
  &__header {
    text-align: left;
    margin: 40px 0 15px;
    display: flex;
    justify-content: space-between;
    height: 63px;
    .gbip-headline-medium {
      button {
        display: inline;
        fill: $color--mid-gray;
      }
    }
    &-actions {
      display: flex;
      flex-direction: column;
      > div {
        justify-content: flex-end;
        align-items: center;
        display: flex;
        > div {
          padding: 0px 5px;
        }
      }
    }
    .gbip-search-tags > span {
      text-decoration: underline;
    }
  }
  &__bar {
    display: flex;
    align-items: center;
    static-include {
      flex: 2;
    }
    view-options {
      border: 1px solid $color--light-gray;
      border-right: none;
      height: 100%;
      display: flex;
      align-items: center;
      .gbip-view-options {
        display: flex;
        justify-content: center;
        button {
          margin: 0px;
          &:nth-of-type(1) {
            margin-left: 15px;
          }
          &:nth-last-child(1) {
            margin-right: 15px;
            margin-left: 7px;
          }
        }
      }
    }
  }
  &__main {
    position: relative;
  }
  &__compare {
    position: fixed;
    left: $size--sidebar + ($size--column-gutter * 2);
    right: $size--column-gutter * 2;
    bottom: 0;
    min-width: static-width(10);
    z-index: 1;
  }
  .gbip-search-sidebar {
    &__link {
      margin-top: 70px;
      margin-bottom: 70px;
      a {
        color: $color--near-black;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    &__filters {
      margin-top: 165px;
      margin-bottom: 70px;
    }
  }
}

.gbip-searchview-results-column {
  max-width: 100%;
  min-height: 32px;
}
