$button--size--y: 0.75em;
$button--size--x: 1.5em;

.gbip-button {
  @include gbip-headline($font-size--button);
  align-items: center;
  border: 1px solid;
  border-radius: $size--corners;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  letter-spacing: -0.2px;
  padding: $button--size--y $button--size--x;
  position: relative;
  width: auto;

  &:focus {
    outline: none;
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }

  &--square {
    padding: $button--size--y;
  }

  &--transparent {
    border-color: transparent;
  }

  &--full-width {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  &--primary {
    background-color: $color--near-black;
    border-color: $color--near-black;
    color: $color--white;

    &:focus,
    &.is-active {
      border-color: $color--orange;
    }

    &[disabled] {
      background-color: $color--mid-gray;
      border-color: $color--mid-gray;
    }
  }

  &--secondary {
    background-color: $color--near-white;
    border-color: $color--lighter-gray;
    color: $color--near-black;

    &:focus,
    &.is-active {
      border-color: $color--dark-gray;
    }

    &[disabled] {
      opacity: 0.6;
    }
  }

  &--tertiary {
    background-color: $color--white;
    border-color: $color--lighter-gray;
    color: $color--near-black;

    &:focus,
    &.is-active {
      border-color: $color--dark-gray;
    }

    &[disabled] {
      opacity: 0.6;
    }
  }

  &--checkbox {
    font-family: $ff--body;
    font-size: $font-size--small;
    color: $color--dark-gray;
    font-weight: normal;

    &:focus {
      border-color: $color--lighter-gray;
    }

    &.is-active {
      color: white;
      background-color: $color--near-black;
      border-color: $color--near-black;
    }
  }

  &__loading {
    @include center();
    pointer-events: none;
  }

  &__icon {
    margin-left: $size--column-gutter/2;
    width: 10px;
  }
}

.gbip-link-button {
  @include gbip-headline($font-size--button);

  &--primary {
    &.is-active {
      color: $color--near-black;
    }
  }
}

.cta-container {
  padding-top: 10px;
}
