.gbip-initiate-job {
  &__headline {
    margin-bottom: $size--column-gutter;
    flex-grow: 1;
    width: 40%;
    margin-right: 30px;
    h1 {
      font-size: 38px;
      margin-bottom: 10px;
    }
    h3 {
      font-size: 27px;
    }
    h4 {
      color: $color--dark-gray;
      font-size: 16px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    product-detail-modal {
      flex-grow: 1;
    }
  }
  &__body {
    display: flex;
    justify-content: space-between;
    &--jobInfo {
      flex-grow: 1;
      width: 33%;
    }
    &--shootDetails {
      flex-grow: 1;
      width: 32%;
      &-select {
        color: $color--mid-gray;
        padding: 20px 0;
      }
    }
    &--lineart {
      flex-grow: 1;
      width: 35%;
    }
    &--section {
      margin-bottom: 2em;
    }
  }
  &__label {
    font-size: $font-size--small;
  }
  &__error {
    color: $color--red;
    padding-bottom: 15px;
  }
  &__warning {
    color: $color--dark-gray;
  }
  &__container {
    background-color: $color--white;
    border: 1px solid $color--lighter-gray;
    margin: 0 0 8px 0;
    &--assets {
      // normal padding + asset gallery item padding
      padding: $size--column-gutter/2 + 10px;
    }
    &--shoot-detail {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 0px;
    }
    &--shoot-detail:hover {
      border-color: $color--near-black;
    }
    &--shoot-detail:hover .gbip-edit-shoot-info__view-code-icon {
      display: inline-block;
    }
    &--shoot-detail:hover .gbip-edit-shoot-info__view-codes {
      margin-bottom: 0;
      transition: ease-in-out, margin 200ms ease-in-out;
    }
    &--shoot-detail:hover .gbip-edit-shoot-info__view-codes.edit {
      margin-bottom: -10px;
    }
    &.is-disabled {
      background-color: $color--disabled !important;
    }
  }
  &__priorityToggle {
    width: 48px;
    height: 48px;
    border: 2px $color--light-gray solid;
    fill: $color--dark-gray;
    padding: 12px;
    &.active {
      border-color: $color--red;
      fill: $color--red;
      &.p {
        border-color: $color--yellow;
        fill: $color--yellow;
      }
    }
    &.disabled {
      border-color: $color--light-gray;
      fill: $color--light-gray;
    }
  }
  &__confidentialToggleWrapper {
    width: 100%;
    padding-right: 31px;
  }
  &__confidentialToggleActions {
    display: flex;
    justify-content: flex-start;
  }
  &__confidentialToggle {
    width: 48px;
    height: 48px;
    border: 2px $color--light-gray solid;
    fill: $color--dark-gray;
    padding: 12px;
    margin: 0 10px 0 0;
    &.active {
      border-color: $color--red;
      fill: $color--red;
    }
    &.disabled {
      border-color: $color--light-gray;
      fill: $color--light-gray;
    }
  }
  &__confidentialToggleDate {
    padding: 10px 0 0 0;
    select {
      margin-bottom: 5px;
    }
  }
  &__close-job {
    margin: 15px 0 0 0;
    a {
      text-decoration: underline;
      color: $color--dark-gray;
    }
  }
  &__close-job-modal {
    margin: 15px 0 0 0;
    text-align: center;
    h1 {
      font-size: 38px;
      margin-bottom: 10px;
    }
  }
  .gbip-asset-preview__image-container {
    min-height: 0px;
    max-height: 200px;
  }
}

.priority-inline {
  .gbip-initiate-job__priorityToggle {
    height: 40px;
    width: 40px;
    padding: 9px;
  }
}

.fade {
  transition: all linear 500ms;
  opacity: 1;
}

.fade.ng-hide {
  opacity: 0;
}
