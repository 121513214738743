.gbip-search-compare {
  background-color: $color--near-white;
  border: 1px solid $color--mid-gray;
  padding: 20px  0 20px 10px;

  &__header {
    position: relative;
    outline: none;
    cursor: pointer;
  }

    &__icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 20px;
      fill: $color--dark-gray;
    }

  &__body {
    margin-top: 20px;

    &--list {
      height: 100px;
    }

    &--grid {
      height: 320px;
    }
    
    &:not(.is-open) {
      display: none;
    }
  }
}

  .gbip-search-compare-content {
    padding-right: 20px;
    display: flex;

    &--list {
      flex-direction: column;
    }

    &--grid {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .gbip-search-compare-job {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
    
    &--list {
      flex-direction: column;
    }

    &--grid {
      flex-direction: row;
      max-width: 50%;
    }
    
    &__notes {
      flex-grow: 1;
      margin: 10px 5px 5px;
    }
  }

    .gbip-search-compare-job-notes {
      display: flex;

      &__body {
        flex-grow: 1;
        margin-right: 20px;
      }

      &__toggle {
        margin-left: auto;
        font-size: $font-size--small;
        white-space: nowrap;
      }
    }

      .gbip-search-compare-note {
        color: $color--dark-gray;

        &:not(:first-child) {
          margin-top: 10px;
        }
      }