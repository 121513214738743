.gbip-filter-tag {
    display: inline-flex;
    align-items: center;
    font-size: $font-size--small;
    font-family: $ff--body;
    line-height: $line-height--small;
    margin-right: $line-height--small;
    border: 1px solid $color--lighter-gray;
    background-color: $color--near-white;
    margin-bottom: 5px;
    &__icon {
        width: 10px;
        height: 100%;
        margin-left: 5px;
        fill: $color--near-black;
        // margin-right: 2px;
        &:hover {
            fill: $color--dark-gray;
        }
    }
    &__label {
        margin-left: 0.5em;
        padding: 6px;
        border-left: 1px solid $color--lighter-gray;
        // color: red;
        &__parent {
            color: $color--dark-gray;
        }
        &__value {
            color: $color--near-black;
        }
    }
}