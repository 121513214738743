.gbip-queue-meta {
    &__copy {
        min-height: $line-height--body;
    }
    &__toggle {
        font-size: 12px;
        text-align: right;
    }
    &__assign {
        color: $color--mid-gray;
        text-align: right;
        button {
            margin-left: 5px;
            &:first-child {
                margin-right: 5px;
            }
        }
    }
    &__buttons {
        display: flex;
    }
    &__actions {
        display: flex;
        justify-content: flex-end;
        height: 40px; //SHAME – buttons inside this parent have some weird rounding error that causes thepage to jump a bit
    }
    &__cta {
        &+& {
            margin-left: $size--column-gutter;
        }
    }
    &__download-button {
        width: $font-size--button;
    }
}