.gbip-admin {
  padding-top: $size--column-gutter * 3;
  position: relative;

  &__header {
    text-align: center;
  }

  &__sections,
  &__response {
    margin-top: $size--column-gutter * 3;
  }

  &__version {
    @include absolute-position(
      $top: $size--column-gutter,
      $right: $size--column-gutter * 1
    );
    color: $color--mid-gray;
    font-size: $font-size--small;
    line-height: $line-height--small;
    background-color: $color--white;
    padding-left: 10px;

    &__extra-data {
      text-indent: 5px;
    }
    &__extra-extra-data {
      text-indent: 10px;
    }
  }

  &__asset-request {
    margin: 0 auto;
    text-align: center;
    .gbip-row {
      padding: 35px;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid $color--mid-gray;
      margin-bottom: 35px;
      form-dropdown {
        text-align: left;
        width: 220px;
        padding-right: 10px;
        .gbip-dropdown__selection {
          width: auto;
          max-width: 100px;
          display: inline-block;
          overflow: hidden;
          line-height: 10px;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &__asset-request {
    margin: 0 auto;
    text-align: center;
    .gbip-row {
      padding: 35px;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid $color--mid-gray;
      margin-bottom: 35px;
      form-dropdown {
        text-align: left;
        width: 220px;
        padding-right: 10px;
        .gbip-dropdown__selection {
          width: auto;
          max-width: 100px;
          display: inline-block;
          overflow: hidden;
          line-height: 10px;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
