.gbip-admin {
  &__studios {
    background-color: $color--near-white;
    padding: $size--column-gutter/2;
  }

    &__studios-header {
      align-items: center;
      padding: $size--column-gutter;
      position: relative;
      display: flex;
    }

      &__studios-cta {
        margin-left: auto
      }
}

.gbip-studio {
  border-top: 1px solid $color--lighter-gray;
  display: flex;
  padding: $size--column-gutter;

  &__header {
    margin-bottom: $size--column-gutter * 2;
  }

  &__info {
    flex: 1 0 auto;
  }
}

  .gbip-studio-details {
    color: $color--dark-gray;
    font-size: $font-size--small;
    line-height: $line-height--body;
    margin-bottom: $size--column-gutter * 2;

    &__item {
      list-style-type: none;
    }
  }

.gbip-manage-studio {

  &__section {
    font-size: $font-size--small;
    line-height: $line-height--small;

    & + & {
      margin-top: $size--column-gutter * 2;
    }
  }

    &__section-header {
      font-weight: bold;

      &--optional {
        color: $color--dark-gray;
        font-weight: normal;
      }
    }
}

.gbip-studio-ftp-code {
  align-items: center;
  display: flex;
  margin-top: $size--column-gutter;

  &__section {
    position: relative;

    & + & {
      margin-left: $size--column-gutter;
    }

    &--password {
      min-width: 150px;
    }
  }

    &__expiration-string {
      @include absolute-position(100%, 0)
      font-size: 11px;
      line-height: $line-height--body;
      text-align: center;
      width: 100%;
    }
}