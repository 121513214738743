// allow us to overwrite default color of element when referenced by <use>
svg path, svg polygon {
  fill: inherit;
}

.gbip-icon-container {
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 100%;

  &--chevron {
    padding-bottom: 63%;
  }

  &--chevron-left,
  &--chevron-right {
    padding-bottom: 174%;
  }

  &--swoosh {
    padding-bottom: 32%;
  }

  &--icon1 {
    padding-bottom: 32%;
  }

  &--arrow-up,
  &--arrow-down {
    padding-bottom: 187%;
  }

  &--menu {
    padding-bottom: 58%;
  }

  &--minus {
    padding-bottom: 14%;
  }

  &--note {
    padding-bottom: 112%;
  }

  &--pic {
    padding-bottom: 75%;
  }
}

  .gbip-icon {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
