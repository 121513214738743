.gbip-merch {
  height: calc(#{$height--content} - 187px);
  min-width: $size--content;
  &__header {
    display: flex;
    align-items: flex-start;
    padding-bottom: 20px;
    &-side {
      position: relative;
      justify-content: flex-start;
      padding-left: 22px;
      display: block;
      flex-direction: column;
      flex-grow: 3;
      .pre,
      .post {
        // height: 14px;
        flex-grow: 0;
        flex-shrink: 3;
      }
      .title {
        flex-grow: 3;
      }
    }
    .headerIcon {
      width: 48px;
      display: block;
      float: left;
    }
    &-cta {
      right: 32px;
      top: 188px;
      position: absolute;
    }
    &-cta-ship {
      right: 32px;
      top: 212px;
      position: absolute;
    }
  }
  &__completedShipment {
    padding-bottom: 10px;
    color: $color--dark-gray;
    font-size: $font-size--small;
  }
  &__subNav {
    width: 100%;
    .header {
      background-color: $color--near-black;
      color: $color--white;
      padding-left: 20px;
      font-family: $ff--brand;
      font-weight: bold;
      font-size: 20px;
      text-transform: uppercase;
      height: 40px;
      line-height: 40px;
    }
    .nav {
      background-color: $color--near-white;
      height: 52px;
      width: 100%;
      .navItems {
        margin: 0 15px;
        line-height: 52px;
        list-style-type: none;
        li {
          float: left;
          line-height: 52px;
          margin: 0 5px;
          width: 110px;
          button {
            width: 100%;
          }
        }
        .gbip-merch__ship--scanning {
          float: right;
          white-space: nowrap;
          margin-right: 50px;
          .highlight {
            color: $color--orange;
          }
          .gbip-icon-container {
            width: 18px;
            height: 18px;
            float: left;
            padding: 49px 0 0 0;
            margin: 0 10px 0 0;
          }
        }
      }
    }
  }
  &__manage {
    margin-top: 28px;
    .gbip-merch {
      &__header {
        justify-content: space-between;
        &-title {
          display: flex;
        }
      }
    }
  }
  &__ship {
    margin-top: 28px;
    background-color: $color--white;
    padding: 0 32px;
    .gbip-merch {
      &__header {
        padding-bottom: 20px;
        &-spacer {
          width: 2px;
          height: 60px;
          flex-grow: 0;
          background-color: $color--light-gray;
          align-self: center;
          margin: 0 20px;
        }
        &-side {
          &:first-of-type {
            padding-left: 22px;
          }
          padding-left: 0;
          flex-grow: 0;
          &.details {
            padding-top: 5px;
            font-family: $ff--body;
            font-size: $font-size--small;
            .value {
              font-weight: bold;
            }
          }
          .title {
            height: 38px;
          }
          .action,
          .location .count {
            font-family: $ff--brand;
            color: $color--dark-gray;
            text-transform: uppercase;
          }
          .edit {
            color: $color--dark-gray;
            font-family: $ff--body;
            font-size: $font-size--small;
          }
          .action {
            font-size: $ff--body;
            font-weight: bold;
          }
        }
        &-buttons {
          flex-direction: column;
          justify-content: space-between;
          margin-left: auto;
        }
        .nav {
          background-color: $color--near-white;
          height: 52px;
          width: 100%;
          .navItems {
            margin: 0 15px;
            line-height: 52px;
            list-style-type: none;
            li {
              float: left;
              line-height: 52px;
              margin: 0 5px;
              width: 110px;
              button {
                width: 100%;
              }
              .scanning {
                float: right;
                margin-right: 50px;
              }
            }
          }
        }
      }
      &__manage {
        margin-top: 28px;
        .gbip-merch {
          &__header {
            justify-content: space-between;
            &-title {
              display: flex;
            }
          }
        }
      }
      &__ship {
        margin-top: 28px;
        background-color: $color--white;
        padding: 0 32px;
        .gbip-merch {
          &__header {
            &-side {
              margin: 0 0 40px 0;
              .title {
                height: 38px;
              }
              .action,
              .location .count {
                font-family: $ff--brand;
                color: $color--dark-gray;
                text-transform: uppercase;
              }
              .edit {
                color: $color--dark-gray;
                font-family: $ff--body;
                font-size: $font-size--small;
              }
              .action {
                font-size: $ff--body;
                font-weight: bold;
              }
            }
            &-buttons {
              margin-left: auto;
            }
          }
        }
        &--open {
          padding: 0;
          border-bottom: 1px $color--mid-gray solid;
          margin: 0 0 20px 0;
        }
      }
      &__receive {
        margin-top: 28px;
        .gbip-merch {
          &__header {
            &-side {
              .title {
                height: 38px;
              }
              .action,
              .location .count {
                font-family: $ff--brand;
                color: $color--dark-gray;
                text-transform: uppercase;
              }
              .edit {
                color: $color--dark-gray;
                font-family: $ff--body;
                font-size: $font-size--small;
              }
              .action {
                font-size: $ff--body;
                font-weight: bold;
              }
              .locationFieldError {
                color: red;
                font-size: $font-size--body;
                font-family: $ff--body;
                font-weight: normal;
                text-transform: none;
                padding-left: 200px;
              }
            }
          }
        }
      }
    }
    .results {
      min-height: 200px;
    }
  }
  &__receive {
    margin-top: 28px;
    .gbip-merch {
      &__manual-cpspub-sync {
        color: $color--dark-gray;
        text-decoration: underline;
      }
      &__manual-cpspub-sync-clicked {
        color: $color--dark-gray;
        text-decoration: none;
      }
      &__header {
        justify-content: space-between;
        .details {
          display: flex;
        }
        .manual-form {
          align-self: flex-end;
          width: 300px;
          .error {
            color: $color--red;
          }
          .gbip-search {
            color: $color--near-black;
            &__icon {
              fill: $color--near-black;
            }
          }
        }
        &-side {
          padding-bottom: 15px;
          .title {
            height: 38px;
          }
          .action,
          .location .count {
            font-family: $ff--brand;
            color: $color--dark-gray;
            text-transform: uppercase;
          }
          .edit {
            color: $color--dark-gray;
            font-family: $ff--body;
            font-size: $font-size--small;
          }
          .action {
            font-size: $ff--body;
            font-weight: bold;
          }
          .locationFieldError {
            color: red;
            font-size: $font-size--body;
            font-family: $ff--body;
            font-weight: normal;
            text-transform: none;
            padding-left: 200px;
          }
        }
      }
      &-no-results {
        margin: 40px 0 0 0;
      }
    }
    .location-form {
      margin-top: 14px;
      display: flex;
      > *:not(:first-child) {
        margin: 0px 7px;
      }
      .studioOptions {
        min-width: 205px;
      }
      .viewOptions {
        width: 205px;
      }
      .businessPartner {
        width: 275px;
      }
    }
  }
  &-no-results {
    margin: 40px 0 0 0;
  }
  &--scanning {
    float: right !important;
    white-space: nowrap;
    margin-right: 50px !important;
    .highlight {
      color: $color--orange;
    }
    .gbip-icon-container {
      width: 18px;
      height: 18px;
      float: left;
      padding: 49px 0 0 0;
      margin: 0 10px 0 0;
    }
  }
}

.gbip-merch-mng-update-job {
  &__headline {
    margin-bottom: $size--column-gutter;
    flex-grow: 1;
    width: 100%;
    margin: 0 30px 30px 0;
    h1 {
      font-size: 35px;
    }
    h3 {
      font-size: 25px;
    }
  }
  &__error {
    color: $color--red;
    padding: 0 0 10px 0;
  }
  header {
    padding: 35px 20px 0 20px;
  }
  &__body {
    padding: 0 0px 35px 20px;
    display: flex; // justify-content: space-between;
    &--edit-properties {
      flex: 0 0 220px;
      margin: 0 10px 0 0;
    }
    &--more-info {
      padding-left: 30px;
      flex: 0 1 260px;
      margin: 0 0 0 10px;
      color: $color--dark-gray;
      .gbip-sub-headline {
        color: #000;
      }
      .properties {
        .key {
          color: $color--dark-gray;
        }
        .value {
          color: $color--near-black;
        }
      }
    }
    &--summary {
      padding-left: 30px;
      .split {
        display: flex;
        .product-summary {
          padding-left: 30px;
          .key {
            color: $color--dark-gray;
          }
          .value {
            text-transform: uppercase;
            font-weight: bold;
          }
        }
        .lineart {
          color: $color--dark-gray;
          .thumb {
            .gbip-asset-preview__image {
              widows: 120px;
              height: 120px;
            }
            .type {
              // color: red;
              font-size: $font-size--small;
            }
          }
        }
      }
    }
    .gbip-merch-row {
      margin: 0 0 10px 0;
      &.properties {
        font-size: $font-size--small;
      }
    }
    .actionButton {
      display: flex;
      width: 110px;
      justify-content: space-between;
      text-decoration: underline;
      a {
        color: $color--dark-gray;
      }
      gbip-icon {
        margin-right: 5px;
        width: 18px;
        svg {
          fill: $color--dark-gray;
        }
      }
    }
    .gbip-sub-headline {
      margin-bottom: 0.75em;
    }
  }
}

.gbip-merch-mng-bulk-location {
  .gbip-headline {
    padding-bottom: 15px;
  }
  .subHeader {
    font-family: $ff--body;
    color: $color--dark-gray;
    font-size: $font-size--small;
    padding-bottom: 15px;
    display: block;
  }
  .locationInput {
    display: flex;
    align-items: center;
    .gbip-input {
      width: 177px;
      margin-right: 20px;
    }
    .error {
      color: red;
    }
    padding-bottom: 25px;
  }
  .backendError {
    color: red;
  }
  .gbip-queue-job__section {
    font-size: 12px;
    font-family: $ff--body;
    margin: 0 10px;
    &.bold {
      font-weight: bold;
    }
  }
  .selectedJobs {
    max-height: 300px;
    overflow-y: scroll;
    .gbip-queue-job__section--product-record {
      width: 10%;
    }
    .gbip-queue-job__section--style-name {
      min-width: 30%;
      overflow: initial;
    }
    .gbip-queue-job__section--size {
      width: 10%;
      flex-shrink: 2;
    }
    .gbip-queue-job__section--merch-type {
      width: 15%;
      flex-grow: 1;
    }
    .gbip-queue-job__section--primary-color {
      width: 15%;
      flex-grow: 1;
    }
  }
}

receive-merch-modal,
close-merch-shipment-modal {
  .titles {
    padding-bottom: 30px;
    font-family: $ff--brand;
    font-weight: bold;
    text-transform: uppercase;
    h1 {
      font-size: $font-size--headline;
      padding-bottom: 10px;
      line-height: 1em;
    }
    .name {
      font-size: $font-size--button;
    }
    .help-text {
      font-family: $ff--body;
      font-size: $font-size--body + 2;
      font-weight: 400;
      text-transform: none;
      color: $color--dark-gray;
    }
  }
  .content {
    display: flex;
    .left-half,
    .right-half {
      flex-grow: 1;
    }
    .left-half {
      .querying {
        &-item {
          display: flex;
          padding-bottom: 10px;
          &__icon {
            margin-right: 12px;
            width: 18px; // fill: green;
            fill: white;
          }
        }
      }
    }
    .right-half {
      .thumbnails {
        display: flex;
        justify-content: space-around;
        max-height: 300px;
        .thumb {
          font-size: $font-size--small;
          height: auto;
          background-color: #f7f7f7;
          border: 1px solid #dddddd;
          max-height: 100%;
          max-width: 100%;
        }
      }
      .missing {
        width: 200px;
        height: 200px;
      }
    }
  }
  .thumb {
    display: flex;
    justify-content: center;
    width: 150px;
    height: 120px;
    padding: 5px;
    color: $color--dark-gray;
    div {
      line-height: 16px;
    }
  }
  .location {
    padding-bottom: 15px;
    &-value {
      display: inline-block;
      font-weight: bold;
    }
  }
  .merch-size {
    margin-top: 20px;
    max-width: 50%;
    .error {
      color: $color--red;
      padding: 0 0 10px 0;
    }
    .gbip-input {
      font-size: $font-size--body;
    }
  }
  .content-full {
    width: 100%;
    > *:not(:first-child) {
      border-top: 1px solid $color--light-gray;
      padding-top: 30px;
    }
    .multiple-result {
      font-family: $ff--brand;
      width: 100%;
      height: 220px;
    }
    h1 {
      font-size: $font-size--headline - 6;
    }
    .name {
      font-family: $ff--body;
      font-weight: bold;
    }
    .type {
      font-family: $ff--body;
    }
    .thumb {
      font-size: $font-size--body;
      float: left;
      margin-right: 10px;
    }
    .product {
      padding-top: 10px;
    }
    .action {
      float: right;
      position: relative;
      right: 25px;
      bottom: 80px;
    }
    .no-lineart {
      width: 120px;
      height: 120px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      background-color: $color--light-gray;
    }
  }
}

close-merch-shipment-modal {
  .titles {
    .name {
      font-family: $ff--body;
      font-size: $font-size--button;
      font-weight: normal;
      text-transform: none;
      color: $color--mid-gray;
    }
  }
  .shipment-details-header {
    text-transform: uppercase;
    margin: 0 0 20px 0;
    font-family: $ff--brand;
  }
  .error {
    color: $color--red;
    margin: 0 0 10px 0;
  }
  .left-half,
  .right-half {
    flex-grow: 0;
    width: 50%;
  }
  .results {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 200px;
    margin-top: 10px;
  }
}

gbip-modal[name='checkin-error'] {
  text-align: center;
  p {
    padding: 5px 0px;
  }
}

.gbip-merch-mng-slate {
  width: 700px;
  padding-left: 10px;
  padding-top: 30px;
  .header-icon {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 45px;
    left: 18px;
  }
  .gbip-icon-container,
  .gbip-icon {
    height: 50px;
    width: 50px;
  }
  .gbip-slate-info {
    padding-top: 32px;
    .label {
      width: 150px;
    }
    &__item {
      font-size: 18px;
      line-height: 24px;
      &--merch-type {
        display: inline-block;
        width: auto;
        font-family: $ff--brand;
        text-transform: uppercase;
        padding: 6px;
        font-size: 36px;
        line-height: 36px;
        font-weight: bold;
      }
      &--style-color {
        font-size: 40px;
        line-height: 40px;
        width: 525px;
      }
      &--name {
        font-size: 24px;
        line-height: 28px;
        width: 525px;
      }
    }
    .outline {
      border: 1px solid black;
    }
  }
  .gbip-slate__section {
    margin: 0 !important;
    .bottom-right {
      width: 200px;
      position: absolute;
      left: 480px;
      top: 85px;
      text-align: right;
    }
  }
  .gbip-slate-heading {
    padding-left: 55px;
    height: 60px;
  }
  .qr-code {
    position: absolute;
    top: 40px;
    left: 590px;
  }
}

.home-link {
  // position: absolute;
  // right: 0px;
  // top: 0px;
  font-size: 12px;
}

.working-notification {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 999;
  .loading-container {
    width: 10px;
    padding-right: 18px;
  }
}

.gbip-merch-mng-shipping-slate {
  position: relative;
  font-family: $ff--brand;
  width: 400px;
  height: 600px;
  padding-left: 40px;
  padding-top: 30px;

  .gbip-merch-mng-slate__style-name {
    width: 200px;
  }
  &__section {
    font-size: 0.9rem;
  }
  &__header {
    margin-bottom: 45px;
    text-transform: uppercase;
    text-align: center;
    padding: 45px;
    border-bottom: 1px $color--mid-gray solid;
  }
  &__destination {
    font-size: 36px;
    font-weight: bold;
    margin: 0 0 10px 0;
    .building {
      margin: 0 0 10px 0;
      line-height: 1em;
    }
    .value {
      font-size: 13px;
      font-weight: bold;
      font-family: $ff--body;
      line-height: 1em;
    }
  }
  &__qr-code {
    text-align: center;
    width: 161px;
    margin: 0 auto;
    font-family: $ff--body;
    font-size: 13px;
    font-weight: bold;
  }
  &__footer {
    width: 100%;
    font-size: 34px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    ul {
      display: flex;
      justify-content: space-around;
      list-style: none;
      margin: 30px auto;
      width: 50%;
      font-size: 28px;
      li {
        display: inline-block;
      }
    }
  }
  &__value {
    font-weight: initial;
  }
}

.gbip-merch-mng-new-shipment {
  .subheader {
    font-family: $ff--brand;
    font-size: $font-size--body;
    font-weight: bold;
    text-transform: uppercase;
  }
  .top {
    margin-top: 25px;
    width: 40%;
    .gbip-input {
      margin-top: 30px;
    }
  }
  .bottom {
    display: flex;
    margin-top: 30px;
    .left,
    .right {
      flex-basis: 50%;
    }
    .divider {
      margin: 25px 10% 25px 10%;
      width: 2px;
      background-color: $color--light-gray;
    }
    .right {
      .gbip-dropdown,
      .gbip-input {
        margin-bottom: 10px;
      }
    }
  }
}

.gbip-merch-mng-packing-slip {
  .shipment-from {
    padding: 0 0 20px 0;
    color: $color--mid-gray;
  }
  .packing-slip-header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 50px 0;
    .left-column {
      flex-grow: 3;
      .shipment-destination {
        font-family: $ff--brand;
        font-weight: bold;
        text-transform: uppercase;
        h1 {
          font-size: $font-size--headline;
          padding-bottom: 10px;
        }
      }
      .shipment-recipient {
        font-weight: bold;
        font-family: $ff--brand;
        text-transform: uppercase;
        font-size: $font-size--button;
        margin: 0 0 20px 0;
      }
    }
    .center-column {
      padding: 0 0 20px;
      .shipment-id {
        padding: 0 0 10px 0;
      }
    }
    .right-column {
      padding: 0px 20px 20px;
    }
  }
  .packing-slip-merch {
    .gbip-merch-mng-packing-slip--product-record {
      width: 15%;
    }
    .gbip-merch-mng-packing-slip--style-name {
      width: 25%;
    }
    .gbip-merch-mng-packing-slip--size {
      width: 5%;
    }
    .gbip-merch-mng-packing-slip--merch-type {
      width: 15%;
    }
    .gbip-merch-mng-packing-slip--merchId {
      width: 15%;
    }
    .gbip-merch-mng-packing-slip--primary-color {
      width: 25%;
    }
  }
}

.subheader {
  font-family: $ff--brand;
  font-size: $font-size--body;
  font-weight: bold;
  text-transform: uppercase;
}

.top {
  margin-top: 25px;
  width: 40%;
  .gbip-input {
    margin-top: 30px;
  }
}

.bottom {
  display: flex;
  margin-top: 30px;
  .left,
  .right {
    flex-basis: 50%;
  }
  .divider {
    margin: 25px 10% 25px 10%;
    width: 2px;
    background-color: $color--light-gray;
  }
  .right {
    .gbip-dropdown,
    .gbip-input {
      margin-bottom: 10px;
    }
  }
}

.merch-mng-delete-job-modal--details {
  margin: 10px 0;
}
