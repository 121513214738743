.gbip-download-modal {

  &__header {
    margin-bottom: $size--column-gutter;

    h1 {
      padding-bottom: 5px;
    }
  }

  &__error {
    color: $color--red;
    margin-bottom: 10px;
  }

  &__warning {
    color: $color--dark-gray;
    margin-bottom: 10px;
  }

  &__section {
    margin-bottom: 10px;

    &-header {
      margin-bottom: 10px;
    }

    .radiobutton-container {
      font-size: 12px;

      &[disabled] {
        pointer-events: none;
        color: $color--disabled;
      }
    }
  }

  &__results {
    text-align: center;
    margin: 20px;
  }
}
