.gbip-detail {
  @include fullsize-child();
  height: auto;
  min-height: 100%;
  background-color: $color--near-white;
  min-width: $size--content + ($size--column-gutter * 5);
  z-index: 1;

  &__main {
    margin: ($size--column-gutter * 4) 0;
  }
}