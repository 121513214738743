.assetCountError {
  height: 23;
  color: red;
}

.assetDownload {
  padding: 35px;
  &__filter-group {
    margin-bottom: 40px;
  }
  h1 {
    font-family: $ff--brand;
    font-size: $font-size--sub-headline;
    text-transform: uppercase;
    padding: 5px 0 15px;
  }
}

.assetDownloadHeader,
.assetDownloadBody {
  &-body {
    flex-grow: 2;
  }
  &-filters {
    width: 270px;
  }
}

.assetDownloadHeader {
  display: flex;
  padding-top: 50px;
  &-filters {
    a {
      text-decoration: underline;
      color: $color--near-black;
    }
  }
  &-body {
    font-family: $ff--brand;
    font-size: $font-size--headline;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.assetDownloadBody {
  padding-top: 30px;
  display: flex;
  &-filters {
  }
  &-body {
    gbip-textarea {
      textarea {
        border: 1px solid $color--mid-gray;
      }
      width: 100%;
    }
  }
  .gbip-search-filter {
    padding: 0 20px 0 0;
    &__header {
      padding: 0;
      font-family: $ff--brand;
      text-transform: none;
      &-title {
        font-size: 24px;
      }
    }
    &__filters-not-loaded {
      margin-top: 20px;
      font-weight: normal;
      color: $color--dark-gray;
    }
  }
  .gbip-accordion__group {
    padding: 10px 0;
    &__header {
      font-weight: normal;
      font-family: $ff--body;
    }
    &.is-open {
      .gbip-accordion__header {
        font-weight: bold;
      }
      .gbip-accordion__body {
        margin-top: 10px;
        margin-bottom: 0;
      }
    }
  }
  .assetResults {
    width: 70%;
    border: 1px solid $color--mid-gray;
    .assetTop {
      width: 100%;
      min-height: 150px;
      display: flex;
      justify-content: space-between;
      .assetResults-cards {
        flex-grow: 2;
        justify-content: center;
        display: flex;
        &__loading,
        &__results {
          width: 100%;
          .icons {
            display: flex;
            justify-content: center;
            border-right: 1px solid $color--lighter-gray;
            margin: 25px;
            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 120px;
              flex-basis: 33%;
              .gbip-icon-container {
                width: 50px;
              }
              &-container {
                display: inline-block;
              }
              .description {
                width: 100px;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-left: 15px;
                font-family: $ff--body;
                &-count {
                  font-family: $ff--brand;
                  font-size: 22px;
                  font-weight: bold;
                }
                &-title {
                  color: $color--mid-gray;
                }
              }
              gbip-icon#alert {
                fill: red;
              }
            }
          }
        }
        &__default {
          align-self: center;
          color: $color--mid-gray;
        }
        &__loading {
          .icon-container {
            height: 148px;
            width: 100%;
            .gbip-icon {
              height: 66%;
              &-container {
                margin-left: 25%;
              }
            }
          }
          .description {
            position: absolute;
            loading {
              left: 45px;
            }
          }
        }
      }
      .assetResults-buttons {
        align-self: center;
        display: block;
        padding-right: 20px;
        > .button:first-child {
          padding-bottom: 15px;
        }
      }
    }
    .assetBottom {
      padding: 20px;
      .errors {
        font-size: $font-size--small;
        width: 400px;
        padding: 15px;
        background-color: $color--lighter-gray;
        font-family: $ff--body;
        .icon {
          width: 20px;
          fill: red;
        }
        .copy {
          padding: 10px 0;
          font-style: italic;
        }
      }
    }
  }
}
