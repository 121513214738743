.gbip-qa-reject {
  margin-top: $gbip-modal--header-margin;
  
  &__header {
    color: $color--red;
    text-align: center;
    margin: $line-height--body/2 0;
  }

  &__copy {
    margin: $line-height--body/2 0;
  }

  &__row {
    margin: 0 0 1em 0;
  }
}