.gbip-notes {
  &__group {
    border-bottom: 1px solid $color--lighter-gray;
  }

  &__header {
    @include gbip-headline($font-size--small);
    align-items: center;
    display: flex;
    padding-bottom: 3px;
    padding-top: 3px;
    min-height: 46px;
  }

    &__button-container {
      &--bottom {
        display: flex;
        justify-content: flex-end;
        margin-right: 0.5em;
        margin-top: $size--column-gutter;

        * + * {
          margin-left: $size--column-gutter/2;
        }
      }
    }

    &__textarea {
      @include gbip-textarea(4,8);
    }
}

.gbip-add-note-form {
  margin-bottom: $size--column-gutter;
}



