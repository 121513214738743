.gbip-global-header {
  width: 100%;
  position: relative;

  .gbip-global-header__main {
    border-bottom: 1px solid $color--near-white;
    line-height: 40px;
  }
}

