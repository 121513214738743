$gbip-modal--header-margin: 40px;
.gbip-modal {
  background-color: rgba($color--near-black, 0.7);
  overflow: auto;
  &--expose-bg {
    background-color: transparent !important;
    pointer-events: none !important;
  }
  &[aria-hidden='true'] {
    opacity: 0;
    transform: translateY(-100%);
  }
  &--initiate-job {
    .gbip-dropdown__selection {
      white-space: normal;
    }
  }
  &__main {
    @include static-column(6, width);
    background-color: #fff;
    border: 1px solid $color--dark-gray;
    margin: $size--column-gutter * 2;
    padding: 60px static-width(1);
    position: relative;
    @for $i from 1 through 10 {
      .gbip-modal--#{$i} & {
        @include static-column(#{$i}, width);
        padding: 32px;
        min-height: auto;
      }
    }
    .gbip-modal--full & {
      width: 100vw;
      min-height: 100vh;
      overflow: auto;
      border: none;
    }
    .gbip-modal--wide & {
      width: 85vw;
      max-width: 1300px;
      min-height: 50vh;
      overflow: auto;
      margin: 5vh auto auto auto;
    }
    .gbip-modal--medium & {
      @include static-column(8, width);
      padding: 60px;
      .gbip-container {
        min-width: 0;
      }
    }
    .gbip-modal--small & {
      @include static-column(4, width);
      padding: 32px;
    }
    .gbip-modal--flex & {
      @include static-column(5, width);
      padding: 32px;
      min-height: auto;
    }
  }
  &__header {
    margin-bottom: $gbip-modal--header-margin;
    text-align: center;
    .gbip-modal--error & {
      color: $color--near-black;
    }
  }
  &__copy {
    color: $color--dark-gray;
    margin-top: 10px;
    .gbip-modal--error & {
      color: $color--near-black;
    }
  }
  &__body {
    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
    .shipping-info {
      overflow: hidden;
      margin-bottom: 20px;
      div {
        float: left;
        width: 50%;
      }
    }
    .outbound-form-field {
      color: $color--dark-gray;
    }
  }
  .modal__main {
    margin: 0vh auto auto auto;
  }

  &--error {
    .gbip-modal__body {
      word-wrap: break-word;
    }
  }
}

.gbip-img-modal {
  &__image img {
    max-width: 600px;
    min-height: 600px;
  }
  .modal__main {
    margin: auto;
    position: relative;
    border: 1px solid $color--dark-gray;
  }
  .gbip-detail {
    &-close {
      top: 10px;
      right: 15px;
    }
    &-download {
      position: absolute;
      fill: $color--dark-gray;
      right: 15px;
      width: 25px;
      top: 50px;
      &:hover {
        fill: $color--near-black;
      }
    }
  }
}
