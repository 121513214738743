.gbip-print-slate__job {
  width: 600px;
  font-family: $ff--body;
  font-size: $font-size--sub-headline;
  line-height: $line-height--small;
}

.gbip-slate {
  padding: 10px;
  padding-top: 50px;
  width: 600px;

  &__section {
    width: 100%;
    position: relative;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  &__qr-code {
    height: 100px;
    width: 100px;
    position: absolute;
    top: 0px;
    left: 0px;

    > img {
      display: block;
      width: 100%;
    }
  }

  &__note {
    overflow: hidden;
    word-wrap: break-word;
    max-height: 115px;
  }

  &__flags {
    text-align: center;
    position: absolute;
    top: 0;
    left: 490px;
    width: 80px;

    > div:not(:first-child) {
      margin-top: 5px;
      font-size: 18px;
    }
  }

  .label {
    font-weight: bold;
  }
}

.gbip-slate-flag {
  height: 50px;
  width: 50px;
  margin: auto;
}

.gbip-slate-info {
  width: 600px;
  padding-top: 10px;
  font-family: $ff--body;

  &:after {
    content: '';
    display: table;
    clear: both;
  }

  &__column {
    float: left;

    &--product {
      width: 40%;
      padding-right: 5%;
    }

    &--job {
      width: 55%;
    }
  }

  &__item {
    position: relative;
    padding-right: 15px;
    &:not(.gbip-slate-info__item--wrap) {
      @include ellipsis();
    }

    &--style-color {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 26px;
      line-height: 26px;
    }

    &--name {
      font-size: 18px;
      line-height: 18px;
      width: 390px;
    }

    &--color {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 14px;
    }

    &--season {
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
    }

    &--label {
      font-weight: bold;
    }

    &--age {
      font-size: 12px;
      line-height: 14px;
    }

    &--category {
      font-size: 12px;
      line-height: 14px;
    }

    &--shoot-type {
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      text-transform: uppercase;
      .shoot-type-label {
        font-weight: normal;
        text-transform: none;
        font-size: 12px;
        line-height: 12px;
      }
    }

    &--business-partner-and-job {
      position: absolute;
      right: 15px;
      line-height: 18px;
      text-align: right;
      .gbip-slate-info__item--business-partner {
        font-weight: bold;
        .business-partner-label {
          font-weight: normal;
        }
      }
      .gbip-slate-info__item--job-id {
        .label {
        }
      }
    }

    &--required-views {
      font-size: 20px;
      font-weight: bold;
      border: 1px solid black;
      padding: 5px 0px 4px 0px;
      margin-right: 15px;
      .view-code {
        display: inline;
        padding: 0px 10px;
      }
    }
  }
}

.gbip-slate-heading {
  padding-left: 110px;
  min-height: 95px;
}

slate-modal {
  .gbip-modal__main {
    width: auto;
  }
}

@media print {
  @page {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    size: landscape;
  }
}

.gbip-print {
  transform: scale(1.15);
  padding-left: 100px;
  padding-top: 30px;
}
