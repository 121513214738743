.gbip-sg-article {
  &__header {
    border-bottom: 1px solid $color--lighter-gray;
    border-top: 1px solid $color--lighter-gray;
    padding: 3em 0;
    text-align: center;
  }

  &__body {
    margin: 3em auto;
    max-width: $size--content;
  }
}