.gbip-sort {
  display: flex;

  &__section {
    & + & {
      margin-left: $size--column-gutter/2;
    }
  }
}

  .gbip-sort-direction {
    width: 40px;
    height: 40px;
    padding: 10px;
    display: flex;

    &__item {
      flex: 0 0 50%;
      fill: $color--light-gray;

      &.is-active {
        fill: $color--near-black;
      }
    }
  }