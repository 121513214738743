.gbip-modal--problem {

  .gbip-modal__header {
    margin-bottom: 5px;

  }

  .gbip-queue-modal__problem {

    p {
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 40px;
      padding: 0 2%;
    }

  }

  .gbip-problem-note {

    &__input {
      border: 1px solid $color--light-gray;
      line-height: $line-height--body;

      height: calc(#{$line-height--body * 2} + 11px);
      max-height: calc(#{$line-height--body * 4} + 11px);
      padding: 5px 10px;
      width: 100%;

      &:focus {
        border-color: $color--near-black;
      }
    }

    &__issue-type {
      margin-bottom: 15px;
    }

  }

}