.gbip-asset-hero {
  position: relative;

  &__title {
    font-size: 24px;
    color: $color--dark-gray;
    text-transform: uppercase;
    margin-bottom: $size--column-gutter;

    > b {
      color: $color--near-black;
      font-weight: normal;
    }
  }

  &__img {
    background-color: $color--near-white;
    position: relative;

    img {
      width: 100%;
    }
  }

    &__placeholder {
      @include ratioHeight();
    }

    &__annotation {
      @include absolute-position(0, 0);
      pointer-events: none;
      width: 100%;
      z-index: 1;

      > img {
        width: 100%;
      }
    }

    &__close {
      @include absolute-position($top: 10px, $right: 10px);
      background-color: $color--lighter-gray;
      width: 28px;
      padding: 4px;
      z-index: 2;

      &:hover,
      &:focus {
        background-color: $color--mid-gray;
      }
    }

    &__nav {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      z-index: 2;

      &--back {
        left: 10px;
      }

      &--forward {
        right: 10px;
      }

      &[disabled] {
        fill: $color--mid-gray;
        pointer-events: none;
      }
    }
}
