%gbip-headline {
  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@mixin gbip-headline($text-size) {
  @extend %gbip-headline;
  text-transform: uppercase;
  font: bold $text-size $ff--brand;
  line-height: $line-height--headline;
}

.gbip-headline {
  @include gbip-headline($font-size--headline);
}

.gbip-headline-medium {
  @include gbip-headline(24px);
}

.gbip-headline-small {
  @include gbip-headline(18px);
}

.gbip-sub-headline {
  @include gbip-headline($font-size--sub-headline);
  margin-bottom: 0.25em;
}

.gbip-sub-headline__views {
  font-size: 13px;
  padding-bottom: 1em;
}

.gbip-headline.gray {
  color: $color--dark-gray;
  margin-top: 0.25em;
}
