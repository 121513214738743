.best-asset-solo {
  margin-top: 30px;
  padding: 30px;
  background-color: #fff;

  h1 {
    text-transform: uppercase;
    font-weight: bold;
    padding: 0px 0 15px;
    font-family: 'Trade Gothic', sans-serif;
    font-size: 24px;
  }
}

.gbip-best-asset {
  h2 {
    text-transform: uppercase;
    font-weight: bold;
    padding: 0px 0 15px;
    font-family: 'Trade Gothic', sans-serif;
    font-size: 18px;
    padding-bottom: 0px;
  }
  &__group {
    width: 100%;
    border-bottom: 1px solid $color--mid-gray;
    display: table;
    margin-bottom: 20px;
  }
  &__group:last-child {
    border-bottom: none;
  }
  &__subtitle {
    padding-top: 0;
    font-size: 12px;
    color: $color--dark-gray;
    padding-bottom: 10px;
  }
  &__empty {
    padding: 50px;
    padding-top: 16px;
    margin: auto;
    text-align: center;
    color: $color--mid-gray;
  }

  &__libraryViewCodePlaceholder {
    height: 24px;
    .libraryViewCode {
      position: relative;
      color: $color--dark-gray;
      font-size: 12px;
      line-height: 1.2em;
      padding-bottom: 4px;
      .gbip-image-thumbnail__library-view-code-tooltip {
        float: right;
        top: 1px;
      }
      .unknown-lvc {
        color: $color--red;
      }
      & > span {
        line-height: 20px;
      }
      .code-input {
        border: 1px solid $color--near-black;
        background-color: $color--white;
        color: $color--near-black;
        padding: 2px;
      }
      .lvc-tooltip {
        top: 4px;
      }
    }
  }
  &__shoottype-menu-block {
    float: right;
    > .shoottype-menu-trigger-button {
      width: 50px;
      height: 25px;
      line-height: 10px;
      padding: 0px;
      font-size: 24px;
      padding-bottom: 12px;
      z-index: 50;
    }
    > .shoottype-menu {
      margin-right: -25px;
      margin-bottom: -5px;
      font-size: 12px;
      float: left;
      margin-top: 28px;
      box-shadow: inset 0px 0px 1px $color--near-white;
      border: 1px solid $color--light-gray;
      z-index: 100;
      ul {
        list-style-type: none;
        width: 260px;
        padding: 10px 0px;
        li {
          padding: 2px 0px 2px 15px;
          &.clickable {
            cursor: pointer;
          }
          &.clickable:hover {
            background-color: $color--near-white;
          }
        }
      }
    }
    .save-actions {
      button {
        width: 100px;
      }
      .save-lvc-error-message {
        color: $color--red;
        float: left;
        padding: 10px 10px 0px 0px;
      }
    }
  }
  .gbip-asset-gallery {
    padding-top: 10px;
    .gbip-asset-gallery__item {
      max-width: none;
    }
    .gbip-asset-preview__image-container {
      max-width: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.lvc-typeahead {
  .gbip-dropdown__selection {
    font-weight: normal;
  }
}