.gbip-queue-nav {
  display: flex;
  justify-content: center;
  margin-top: 6px;

  &__item {
    @include gbip-headline($font-size--sub-headline);
  }

    a,
    button {
      border-bottom: 4px solid transparent;
      display: block;
      color: inherit;
      padding: 9px 12px 5px;
      margin: 0 12px;
      text-transform: uppercase;

      &.is-active {
        border-bottom-color: $color--dark-gray;
      }
    }
}