$size--note-spacing: 40px;

.gbip-note {
  font-size: $font-size--small;
  line-height: $line-height--body;
  display: flex;

  &--column {
    flex-direction: column;
  }

  &__section {
    display: flex;

    &--main {
      .gbip-note--column & {
        flex-direction: column;
      }
    }
  }

    &__author,
    &__role,
    &__date {
      white-space: nowrap;
    }

    &__date {
      margin-left: $size--note-spacing;
    }

  &__body {
    color: $color--dark-gray;
    word-wrap: break-word;
    overflow: hidden;

    .gbip-note--row & {
      margin-left: $size--note-spacing;
    }

    .gbip-note--column & {
      margin-bottom: $line-height--body/2;
      max-width: 75%;
    }
  }
}