.gbip-queue-modal {
  width: 100%;
  &__status {
    margin-bottom: 10px;
  }

  &__section,
  &__jobs {
    margin-bottom: 40px;

    .gbip-headline-medium {
      text-align: center;
      &__storage {
        text-align: left;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__error {
    text-align: center;
    color: $color--red;
    margin-top: -20px;
    margin-bottom: 40px;
  }

  &__button {
    &:not(:first-child) {
      margin-left: $size--column-gutter;
    }

    .gbip-queue-modal__actions--modal & {
      flex-basis: 50%;
    }
  }

  &__show-views {
    .gbip-queue-modal {
      width: auto;
    }
  }

  &__reprint-slate-note-message {
    margin: 0px auto;
    > gbip-icon {
      height: 18px;
      padding-right: 5px;
      top: 4px;
      position: relative;
      > .gbip-icon-container {
        padding: 0px;
        width: 18px;
        height: 18px;
        display: inline-block;
        fill: $color--red;
      }
    }
  }
}
status-change {
  .gbip-modal {
    &__main {
      width: auto;

      .gbip-modal-views {
        max-width: 720px;
        margin-left: 25px;
      }
    }

    &__header {
      text-align: left;
      margin-left: -8px;
    }
  }
}

expected-views {
  .gbip-asset-gallery {
    margin-left: 5px;

    thumbnail {
      padding-top: 25px;
    }

    .missing-asset > .gbip-asset-preview {
      box-shadow: 0 0 5px $color--red;
      border: 1px solid $color--red;
    }

    .gbip-asset-preview {
      margin-right: 15px;
      margin-top: 10px;

      &__status {
        margin-bottom: 12px;
      }

      &__image-container {
        min-width: 100px;
      }

      &__info {
        margin-top: 0px;
      }
    }
  }
}
