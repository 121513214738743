.gbip-view-options {
    display: flex;
}

.gbip-view-option {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    outline: none;
    &:not(.is-active) {
        fill: $color--light-gray;
        &:hover,
        &:focus {
            fill: $color--mid-gray;
        }
    }
}