$gbip-history--min-height: 128px;
.gbip-history {
    position: relative;
    min-height: $gbip-history--min-height;
}

.gbip-history-event {
    border-bottom: 1px solid $color--lighter-gray;
    display: flex;
    font-size: $font-size--small;
    padding: $size--column-gutter 0;
    min-height: $gbip-history--min-height;
    &__date {
        flex: 0 0 25%;
        text-align: right;
    }
    &__detail {
        flex: 0 0 65%;
        margin-left: 10%;
    }
}

.gbip-history-archived {
    text-align: center;
    top: 30px;
    position: relative;
}

.gbip-history-detail {
    display: flex;
    width: 100%;
    &__section {
        &--info {
            flex: 1 1 auto;
        }
        &--thumbnail {
            flex: 0 0 88px;
            margin-left: $size--column-gutter;
        }
    }
    &__publish {
        display: flex;
        align-items: flex-start;
        .errorMessage {
            color: $color--red;
            flex: 2;
            display: flex;
            flex-direction: column;
        }
        button {
            padding: 0.50em 1.5em;
            gbip-icon {
                fill: $color--green;
                width: 20px;
            }
        }
    }
    &__rejected-asset {
        padding-bottom: 15px;
        > .rejection-reason {
            padding-top: 15px;            
            > .reason {
                font-weight: bold;
            }            
        }        
    }
    
}

.gbip-history-thumbnail {
    border: 1px solid $color--light-gray;
    position: relative;
    >img {
        background-color: $color--near-white;
        width: 100%;
    }
    &__annotation {
        @include absolute-position(0, 0);
        z-index: 1;
        >img {
            width: 100%;
        }
    }
    &__actions {
        @include absolute-position($top: 6px, $right: 6px);
        display: flex;
        z-index: 2;
    }
    &__action {
        background-color: $color--lighter-gray;
        padding: 3px;
        width: 15px;
        &:hover,
        &:focus {
            background-color: $color--mid-gray;
        }
        &+& {
            margin-left: 5px;
        }
    }
}

.gbip-history-detail-note {
    &__content {
        background-color: $color--near-white;
        padding: $size--column-gutter/2;
        width: 80%;
    }
    >ol {
        margin-left: 1.2em;
    }
}