.gbip-image-viewer {
  &__documented-issues {
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px 0 17px;
    font-family: 'Trade Gothic', sans-serif;
    font-size: 15px;
    text-align: left;
  }
  &__annotation-notes {
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px 0 13px;
    margin-top: 20px;
    font-family: 'Trade Gothic', sans-serif;
    font-size: 15px;
    border-top: 1px solid $color--light-gray;
    text-align: left;
  }
  &__past-annotation-notes {
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px 0 12px;
    margin-top: 20px;
    font-family: 'Trade Gothic', sans-serif;
    font-size: 15px;
    border-top: 1px solid $color--light-gray;
    text-align: left;
  }
  &__asset-rejection {
    font-size: 12px;
    margin-left: 20px;
    margin-bottom: 10px;
    &-comment {
      margin-left: 24px;
    }
    &-title {
      vertical-align: top;
      font-weight: bold;
      .color-swatch {
        &.inline {
          display: inline-block;
          margin-right: 6px;
        }
        &.circle:before {
          content: ' \25CF';
        }
        &.square {
          width: 16px;
          height: 16px;
          &.red {
            background-color: $color--red;
          }
          &.yellow {
            background-color: $color--yellow;
          }
          &.blue {
            background-color: $color--blue;
          }
          &.black {
            background-color: $color--near-black;
          }
        }
      }
    }
  }
  // &-comments {
  font-family: $ff--body;
  margin: 15px 0px;
  &--comments {
    margin-top: 16px;
    .comment {
      margin: 15px 0px;
      .details {
        font-size: 12px;
        .name {
          font-weight: bold;
        }
      }
      .text {
        margin-top: 5px;
      }
    }
  }
  padding-bottom: 15px; // }
  &__section {
    background-color: $color--white;
    padding: $size--column-gutter;
    position: relative;
  }
  &__header {
    display: flex;
  }
  &__headline {
    font-size: 24px;
    color: $color--dark-gray;
    text-transform: uppercase;
    margin-bottom: $size--column-gutter;
    > b {
      color: $color--near-black;
      font-weight: normal;
    }
  }
  &__bottom {
    margin: 15px 0;
    border-top: 1px solid $color--mid-gray;
  }
  &__info-panel {
    position: relative;
  }
  &__hero {
    width: 100%;
  }
  &__no-image {
    @extend %gbip-headline;
    @include fullsize-child();
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color--near-white;
    padding: 20px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  &__image {
    //@include absolute-position($top: 0, $left:0);
    width: 100%;
    background-color: $color--near-white;
    border: 1px solid #d5d5d5;
    .gbip-image-thumbnail & {
    }
  }
  &__image--annotation {
    background-color: transparent;
  }
  &__hero-nav {
    @include vertical-center();
    width: 100%;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    left: 0px;
    top: 300px;
  }
  &__hero-nav-item {
    width: 8px;
    margin: 0 3px;
    pointer-events: auto;
    position: relative;

    &.prev {
      left: -15px;
    }

    &.next {
      right: -15px;
    }
  }
  &__asset-details {
    margin-top: $size--column-gutter/2;
  }
  &__thumbnail-container {
    margin-top: $size--column-gutter;
  }
  &__thumbnail-item {
    width: 114px;
    padding: 0 $size--column-gutter/2;
  }
  &__asset-job-section {
    padding: 12px 8px 8px 8px;
  }
  &__multi-crop-select {
    background-color: $color--mid-gray;
    align-items: center;
  }
}

.gbip-image-viewer-footer {
  margin-top: $size--column-gutter;
  padding-top: $size--column-gutter;
  border-top: 1px solid $color--mid-gray;
  display: flex;
  .gbip-image-viewer--qa & {
    justify-content: flex-end;
    align-items: baseline;
    border-top: none;
    padding-top: 0;
  }
  &__section {
    .gbip-image-viewer--qa & {
      margin-top: auto;
    }
    &--ready {
      flex: 0 0 50%;
      color: $color--dark-gray;
      font-size: $font-size--small;
      line-height: $line-height--small;
    }
    &--upload {
      margin-left: auto;
    }
    &--download-images {
      margin-left: auto;
    }
    &--download-attachments {
      margin-left: 10px;
    }
  }
}

.gbip-image-thumbnail {
  margin-bottom: $size--column-gutter;
  position: relative;
  &__wrapper {
    position: relative;
    margin: 0px 3px 3px 3px;
    .is-inactive & {
      @include imgDarkOverlay();
    }
    &.is-image-selected {
      border: 3px solid $color--dark-gray;
      margin: -3px 0px 0px 0px;
      &:after {
        content: '';
        @include absolute-position($top: 0, $left: 0);
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
    }
  }
  &__view {
    color: $color--dark-gray;
    font-size: $font-size--small;
    line-height: $line-height--small;
    margin-bottom: 1px;
    display: flex;
    justify-content: space-between;
    padding-right: 4px;
    &__crop-type {
      margin-top: $size--column-gutter / 6;
    }
  }
  &__library-view-code {
    color: $color--dark-gray;
    font-size: $font-size--small;
    line-height: $line-height--body;
    padding-bottom: 9px;
    .hidden {
      visibility: hidden;
    }
    &__code-input {
      .gbip-dropdown__toggle {
        padding: 2px;
        .gbip-dropdown__icon {
          display: none;
        }
      }
    }
    .library-view-code-error-group {
      display: flex;
      align-items: center;
    }
  }
  &__library-view-code-tooltip {
    position: absolute;
    right: 11px;
    width: 12px;
    height: 12px;
    top: 4px;
    fill: $color--red;
  }
  &__red {
    color: $color--red;
  }
  &__icon-group {
    width: 19px;
    height: 19px;
    @include absolute-position($top: -8px, $right: 9px);
  }
}

.gbip-thumbnails-header {
  margin: 4px;
  display: flex;
  justify-content: flex-end;
  &__crop-type-toggle {
    margin: 4px;
    width: 15px;
  }
}

.gbip-qa-actions {
  margin-top: $size--column-gutter;
  margin-bottom: $size--column-gutter;
  display: flex;
  position: relative;
  &__buttons {
    display: flex;
  }
  &__rejection {
    width: 100%;
  }
  &__item {
    margin-right: 10px;
    &--icons {
      position: absolute;
      top: 0;
      right: 0;
      border: 1px;
      border-color: #e5e5e5;
      border-style: dashed;
      border-radius: 2px;
      padding: 0px 10px;
      margin-right: 0;
      margin-left: auto;
      height: 40px;
    }
  }
}

.gbip-qa-attachments {
  &__header {
    color: $color--dark-gray;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__delete {
    width: 8px;
    height: 8px;
    fill: $color--dark-gray;
    margin-left: 20px;
    &:hover,
    &:focus {
      fill: $color--near-black;
    }
  }
}

.record-links {
  .gbip-modal__main {
    width: auto;
    padding: 29px 60px;
  }

  .gbip-modal__header {
    text-align: left;
  }

  &__record {
    border: 1px solid $color--mid-gray;
    width: 450px;
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 20px;
    .bottom {
      margin-top: 10px;
      .thumbnails {
        margin-top: 8px;
        padding-right: 20px;
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &--action {
      display: flex;
      flex-direction: column;
      .gbip-button {
        margin: 10px;
      }
    }
  }

  .gbip-job-list {
    padding-top: 10px;
  }

  .gbip-asset-preview__image {
    max-width: 122px;
  }
}

.error {
  color: $color--red;
}

.lvc-typeahead {
  .gbip-dropdown__selection {
    font-weight: normal;
  }
}
