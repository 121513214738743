.gbip-product-sync {
  .gbip-column,
  .gbip-row {
    text-align: center;
  }

  form-dropdown {
    text-align: left;
    width: 175px;
    margin-right: 15px;
    margin-left: auto;
  }

  file-input {
    margin-right: auto;
  }

  &__requests {
    padding: 35px 0;
    border-top: 1px solid $color--mid-gray;
  }

  &__uploads {
    padding-bottom: 25px;
  }

  &__request {
    &-card {
      margin: 20px 50px 0 50px;
      padding: 10px 20px;
      border: 1px solid $color--near-black;
      border-radius: 3px;
      display: flex;
      flex-direction: column;

      a {
        color: $color--near-black;
      }

      &--item {
        display: flex;

        &-info {
          display: flex;
          flex-direction: column;
        }

        &-name {
          color: $color--dark-gray;
        }
      }
    }

    &-row {
      display: flex;
      padding: 10px;
    }

    &-row:nth-child(n + 3):last-child {
      padding-top: 0;
    }
  }

  &--id {
    width: 90px;
  }

  &--user {
    width: 225px;
  }

  &--time {
    width: 150px;
  }

  &--type {
    width: 90px;
  }

  &--delete {
    margin-left: auto;
    fill: $color--mid-gray;
  }

  &--delete:hover {
    fill: $color--dark-gray;
  }

  &--count {
    margin-left: auto;
  }

  &--errors {
    margin-right: 15px;
    color: $color--red;
  }

  &--download {
    margin-right: 15px;
    cursor: pointer;
    .gbip-product-sync__request-card--item-value {
      text-decoration: underline;
    }
  }
}
