.gbip-detail {
  &__qa-header {
    @include gbip-headline(24px);
    background-color: $color--white;
    padding: $size--column-gutter;
    position: relative;
    text-align: center;
  }

    &__qa-cta {
      @include absolute-position($top: 50%, $right: $size--column-gutter);
      transform: translateY(-50%);
    }
}