$response-scroll-height: 124px;

.gbip-admin-response {
  border: 1px solid $color--lighter-gray;
  margin: auto;
  margin-bottom: 16px;
  position: relative;
  width: fluid-width(6);

  ol {
    max-height: $response-scroll-height;
    overflow-y: scroll;
  }

  &--success {
    color: $color--green;
  }

  &--error {
    color: $color--red;
  }

  &__header {
    font-family: $ff--brand;
    font-weight: bold;
    line-height: 42px;
    text-transform: uppercase;
  }

  &__item {
    color: $color--near-black;
    display: flex;
    font-size: $font-size--small;
    line-height: 2.5em;
    list-style: none;
    text-align: left;

    &:nth-child(odd) {
      background: $color--near-white;
    }

    span {
      border-top: 1px solid $color--lighter-gray;
      padding-left: 20px;
    }

    &--single {
      width: fluid-width(12);
    }

    &--row {
      border-right: 1px solid $color--lighter-gray;
      width: fluid-width(2);
    }

    &--msg {
      flex: 1;
    }
  }

  &__close {
    display: block;
    fill: $color--dark-gray;
    height: 1em;
    position: absolute;
    right: 20px;
    top: 16px;
    width: 1em;
  }
}
