.gbip-fileupload-group {
  margin: 1em 0 0 0;
  overflow: auto;
  &__header {
    text-transform: uppercase;
    font-weight: bold;
    padding: 0px 0 15px;
    font-family: 'Trade Gothic', sans-serif;
    font-size: 15px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    position: relative;
    width: 100px;
    height: auto;
    background-color: $color--white;
    padding: 0;
    margin: 0 20px 20px 0;
    cursor: pointer;
    figure {
      font-size: 11px;
      color: $color--dark-gray;
      figcaption {
        background-color: $color--white;
        margin: 0;
        text-align: left;
      }
      figcaption ul {
        padding: 3px 0;
      }
      figcaption ul li {
        margin: 0 0 3px 0;
        list-style: none;
      }
      figcaption ul li:last-of-type {
        margin: 0 0 0 0;
        list-style: none;
      }
    }
    button {
      width: 9px;
      height: 9px;
      margin: 0 5px;
    }
    &:hover .gbip-fileupload-group__actions {
      display: block;
    }
  }
  &__caption {
    color: $color--mid-gray;
    font-size: 10px;
  }
  &__actions {
    position: absolute;
    top: -6px;
    right: -6px;
    display: none;
    background-color: $color--white;
    border-radius: 12px;
    text-align: center;
    padding: 0;
    width: 25px;
    height: 25px;
  }
  &__file {
  }
  &__delete {
  }
  &__error {
    text-align: left;
    &-title {
      color: $color--dark-gray;
    }
    &-body {
      color: $color--mid-gray;
    }
    .alert-icon {
      fill: $color--red;
      width: 15px;
      height: 15px;
      display: inline-block;
      margin: 0 0.5em 0 0.5em;
    }
  }
  &__success {
    text-align: center;
    .success-icon {
      fill: $color--green;
      width: 15px;
      height: 15px;
      display: inline-block;
      margin: 0 0.5em 0 0;
    }
  }
  .gbip-admin-section__feedback .gbip-fileupload-group__error {
    text-align: center;
  }

  &__fco {
    .gbip-fileupload-group__error {
      text-align: center;
      padding: 30px 0;
      width: 100%;
    }
  }

  table {
    margin: 20px 0 0 0;
  }

  table tr th,
  table tr td {
    text-align: left;
    vertical-align: top;
    padding: 3px;
  }
}

.gbip-asset-preview {
  &__info {
    &-filename {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
    }
  }
  .alert-icon {
    width: 18px;
    height: 20px;
    fill: $color--dark-gray;
    text-align: center;
    margin: 0 auto;
    padding: 0 0 10px 0;
  }
  .alert-message {
    padding: 0 15px 0 15px;
    font-size: 13px;
    line-height: 1.25em;
    color: $color--mid-gray;
    text-align: center;
    margin: 5px auto 0;
  }
}

.gbip-fileupload__item {
  width: 100px;
  height: 100px;
  background-color: #f5f5f5;
  padding: 0px;
  margin: 0 20px 20px 0;
  cursor: pointer;
  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    color: #888888;
    fill: #111111;
    text-align: center;
  }
  button.reject {
    padding: 32px;
    cursor: default;
    .gbip-icon-container--rejected {
      fill: #8d8d8d;
    }
  }
  * {
    cursor: inherit;
  }
  &:hover {
    background-color: #d6d6d6;
    button {
      color: $color--light-blue;
    }
  }
  .gbip-icon-container--cloud-upload {
    position: relative;
    width: 33px;
    height: 33px;
    padding-bottom: 0;
  }
}

.gbip-fileupload__svg {
  padding: 10px;
}
