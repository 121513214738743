.gbip-product-delivery {
  margin: 0;

  &__retry-button {
    display: inline;
    float: left;
  }

  &__job {
    padding: 15px;
    width: 100%;
    background-color: $color--near-white;
    margin-bottom: 10px;
    margin-top: 10px;
    transition: background-color 650ms;
    display: flex;
    flex-wrap: wrap;

    justify-content: space-between;

    &.collapsed {
      &:hover {
        background-color: $color--mid-gray;
      }
    }

    .label {
      font-family: $ff--brand;
      font-weight: bold;
    }

    .gbip-product-detail-main__row {
      justify-content: space-between;
    }

    &--content {
      width: 100%;
      padding: 0;
      transition: 300ms linear all;
      overflow: hidden;
    }

    .grow.ng-enter {
      max-height: 0;
    }

    /* The finishing CSS styles for the enter animation */
    .grow.ng-enter.ng-enter-active {
      max-height: 350px;
    }

    .grow.ng-leave {
      max-height: 350px;
    }
    .grow.ng-leave.ng-leave-active {
      max-height: 0;
    }
  }

  &__job:hover {
    .action-link {
      display: inline !important;
    }
  }

  &__heading {
    padding: 25px 15px 0;
    display: flex;
    flex-wrap: wrap;
    font-weight: bold;
    font-size: $font-size--sub-headline;
    justify-content: space-between;
    width: 100%;
  }

  .delivery {
    display: flex;

    &--id {
      width: 80px;
    }

    &--style-color {
      width: 125px;
    }

    &--shoot-type {
      width: 100px;
    }

    &--merch-type {
      width: 100px;
    }

    &--assets {
      width: 80px;
    }

    &--initiated-by {
      width: 140px;
      &.wide {
        width: 190px;
      }
    }

    &--delivered-on {
      width: 180px;
    }

    &--delivery-destination {
      width: 200px;
    }

    &--status {
      width: 310px;

      .gbip-icon {
        height: 18px;
        width: 18px;
        display: inline-block;
        padding-top: 4px;
        margin-right: 6px;
      }

      .success {
        fill: $color--green;
      }

      .failure {
        fill: $color--red;
      }
    }

    &--tooltip {
      @include put-an-arrow-on-it($size--nub, $color--near-black, left);
      display: inline-block;
      position: absolute;
      margin-left: 104px;
      top: -50px;
      pointer-events: all;
      transition: opacity 0ms, transform 0ms;
    }

    .delivery-status-span {
      display: flex;

      .action-link {
        padding-left: 20px;
        display: none;
      }
    }
  }

  .delivery-no-asset {
    padding: 25px 0;
    color: $color--dark-gray;
  }

  .loading-container {
    height: 70px;

    loading {
      padding-top: 40px;
      display: flex;
    }
  }

  .delivery-content {
    padding-top: 20px;

    h2 {
      font-family: $ff--brand;
      text-transform: uppercase;
      font-size: 18px;
      padding-bottom: 10px;
    }

    .gbip-asset-gallery {
      padding-top: 10px;
      margin-right: 250px;

      .gbip-asset-gallery__item {
        max-width: none;

        .is-active {
          text-decoration: none;

          .gbip-tooltip-close {
            position: absolute;
            right: 5px;
            top: 5px;
            fill: white;
            height: 12px;
            width: 12px;
          }

          .tooltip-column {
            display: flex;
            flex-direction: column;

            .tooltip-line {
              padding: 3px 0;
            }
          }
        }

        .gbip-asset-preview__image-container:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
      .gbip-asset-preview__image-container {
        max-width: 100px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .pages {
    height: 50px;
    justify-content: space-between;
    display: flex;
    div:only-child {
      margin-left: auto;
    }
    .invisible {
      width: 5px;
      height: 5px;
    }
  }
}
