.gbip-tabs {
  .gbip-tabs__content {
    pane.scrollable .gbip-pane { 
      max-height: 300px;
      overflow-y: auto;
    }

    pane.scrollable-large .gbip-pane { 
      max-height: 700px;
      overflow-y: auto;
    }
  }

  &__nav {
    border-bottom: 1px solid $color--lighter-gray;
    display: flex;
    justify-content: center;
    padding-bottom: $size--column-gutter;
  }

  &__nav-item:not(:first-of-type) {
    border-left: 1px solid $color--lighter-gray;
    margin-left: 20px;
    padding-left: 20px;
  }

  &__nav-button {
    @include gbip-headline($font-size--button);
    text-transform: uppercase;

    &:not(.is-active) {
      color: $color--mid-gray;
      &:hover {
        color: $color--dark-gray;
      }
    }
  }
}

.gbip-product-tabs {
  margin-top: 30px;
  padding: 30px;
  background-color: #fff;

  .gbip-tabs {
    &__nav {
      border-bottom: none;
      justify-content: left;
    }

    &__nav-item:not(:first-of-type) {
      border-left: none !important;
      margin-left: 0;
      padding-left: 20px;
    }

    &__nav-button {
      text-transform: uppercase;
      font-weight: bold;
      padding: 0px 0 15px;
      font-family: 'Trade Gothic', sans-serif;
      font-size: 24px;
    }

    .is-active {
      text-decoration: underline;
    }
  }
}
