.gbip-annotation {
    position: relative;
    &__nav {
        display: flex;
        margin-left: auto;
        &--item {
            width: $size--column-gutter;
            fill: $color--mid-gray;
            &:hover {
                fill: $color--dark-gray;
            }
            &.active {
                fill: $color--near-black;
            }
            &+& {
                margin-left: $size--column-gutter/2;
            }
        }
    }
    &__submit {
        display: flex;
        justify-content: center;
        margin-top: $size--column-gutter;
        *+* {
            margin-left: $size--column-gutter/2;
        }
    }
    #demoCanvas {
        display: none;
        cursor: default !important;
    }
}

.gbip-annotation-wrapper {
    position: relative;
    button {
        position: absolute;
        width: 35px;
        height: 35px;
        z-index: 1;
    }
    .button-previous {
        position: absolute;
        top: calc(50% - 8px);
        left: -25px;
        -ms-transform: rotate(90deg);
        /* IE 9 */
        -webkit-transform: rotate(90deg);
        /* Chrome, Safari, Opera */
        transform: rotate(90deg);
        padding: 10px;
    }
    .button-next {
        top: calc(50% - 8px);
        right: -25px;
        -ms-transform: rotate(-90deg);
        /* IE 9 */
        -webkit-transform: rotate(-90deg);
        /* Chrome, Safari, Opera */
        transform: rotate(-90deg);
        padding: 10px;
    }
}

.gbip-annotation-history {
    &__item {
        font-size: .9em;
        padding: 0 0 0 10px;
        &.active {
            background-color: #eaeaea;
        }
    }
    &__attribution {
        padding: 0 0 0 7px;

        &-name {
            font-weight: bold;
        }
        &-date {
            margin: 0 0 0 1em;
        }
    }


    &__item:hover {
        background-color: $color--lighter-gray;
    }
}

.gbip-annotation-notes {
    &__item {
        padding: $size--column-gutter/2;
        display: flex;
        align-items: flex-start;
        &:first-child {
            padding-top: $size--column-gutter;
        }
        &:last-child {
            padding-bottom: 0;
        }
        &.center {
            justify-content: center;
        }
    }
    &__item-number {
        box-sizing: content-box;
        font-size: $font-size--small;
        border: 1px solid $color--near-black;
        font-weight: bold;
        border-radius: 50%;
        text-align: center;
        width: $font-size--small * 1.1;
        height: $font-size--small * 1.1;
        padding: $font-size--small/6;
        margin-right: $size--column-gutter/2;
        line-height: 1.2;
    }
    &__textarea {
        flex-grow: 1;
        margin-right: $size--column-gutter/2;
    }
    &__item-remove {
        width: 1.25em;
        height: 1.25em;
        fill: $color--dark-gray;
        &:hover {
            fill: $color--near-black;
        }
    }
    &__instructions {
        color: $color--mid-gray;
        text-align: center;
        font-size: .9em;
        border-top: 1px $color--lighter-gray solid;
        padding: 15px 0 0 0;
    }
  margin-bottom: 10px;
}

.gbip-annotation-canvas {
    @include absolute-position(0, 0);
    &--drawing {
        canvas {
            cursor: url('../../assets/svg/ui/edit.svg'), auto !important;
        }
    }
    &--notes {
        canvas {
            cursor: url('../../assets/svg/ui/note.svg'), auto !important;
        }
    }
    &.hide {
        width: 0%;
        height: 0%;
    }
    &__color {
        @include absolute-position($top: $size--column-gutter, $right:$size--column-gutter);
        display: flex;
        &-header {
            color: $color--dark-gray;
            margin-right: $size--column-gutter/2;
            line-height: $size--column-gutter;
        }
        &-item {
            width: $size--column-gutter;
            height: $size--column-gutter;
            background: white;
            margin-bottom: $size--column-gutter/2;
            &[data="red"] {
                background: $color--red;
            }
            &[data="yellow"] {
                background: $color--yellow;
            }
            &[data="blue"] {
                background: $color--blue;
            }
            &[data="black"] {
                background: $color--near-black;
            }
            &.is-active {
                border: 1px solid $color--dark-gray;
            }
        }
    }
}

.annotation-details {
    .reject-asset-pane {
        .applicable-rejection-reasons {
            .applicable-rejection-reason-title {
                margin-top: 10px;
                margin-bottom: 10px
            }
            .applicable-rejection-reason {
                margin-top: 2px;
                margin-left: 2px;
                font-size: 12px;
                &-previewtext {
                  font-weight: bold;
                }
                &-comment {
                  margin-bottom: 8px;
                }
            }
        }
    }
}

.canvas-container {
    background-color: $color--near-white;
    border: 1px solid $color--grey-border;
}
