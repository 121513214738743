.gbip-merch-details {
  border: 1px solid transparent;
  background-color: $color--white;
  padding: $size--column-gutter - 1px;
  position: relative;

  &--problem-solve {
    border-color: $color--red;
  }
}

.gbip-merch-status {
  display: flex;

  &__item {
    display: flex;

    &--info {
      flex-direction: column;

      & + & {
        border-left: 1px solid $color--lighter-gray;
        margin-left: $size--column-gutter;
        padding-left: $size--column-gutter;

        @include breakpoint(
          (
            min-width: 1800px
          )
        ) {
          margin-left: $size--column-gutter * 3;
        }
      }
    }

    &--icons {
      align-items: center;
      display: flex;
      flex-direction: row;
    }

    &--cta {
      margin-left: auto;

      * + * {
        margin-left: $size--column-gutter/2;
      }
    }

    &--base {
      margin-left: $size--column-gutter/2;
    }
  }

  &__item-name {
    color: $color--dark-gray;
    font-size: 10px;
    line-height: $line-height--small;
    letter-spacing: 0.5px;
  }

  &__item-value {
    display: flex;
    align-items: center;
  }

  &__item-icon {
    height: $size--column-gutter;
    width: $size--column-gutter;
    margin-right: $size--column-gutter/2;
  }

  &__meta-status {
    .gbip-merch-details--problem-solve & {
      color: $color--red;
    }
  }
}

.gbip-merch-icon {
  width: $size--column-gutter;

  & + & {
    margin-left: $size--column-gutter/2;
  }
}

.gbip-edit-merch {
  margin-top: $size--column-gutter;

  &__header {
    color: $color--dark-gray;
  }

  &__error {
    font-size: 11px;
    line-height: $line-height--small;
    color: $color--red;
    display: flex;
    align-items: center;

    &:not(.is-active) {
      display: none;
    }
  }

  &__error-icon {
    width: 12px;
    height: 12px;
    margin: $size--column-gutter/2 $size--column-gutter/2 $size--column-gutter/2
      0;
    fill: currentColor;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    & + & {
      margin-left: $size--column-gutter/2;
    }
  }
}

.gbip-base-details {
  margin-top: $size--column-gutter;

  &__container {
    padding: $size--column-gutter/2;
    &.job-link-error {
      color: $color--red;
      text-align: center;
    }
  }
}

.gbip-problem-solve-detail {
  color: $color--red;
  font-size: $font-size--small;
  line-height: $line-height--small;
  margin-top: $size--column-gutter;

  &__assignee {
    > b {
      font-weight: bold;
    }
  }

  &__date {
    margin-left: $size--column-gutter;
  }
}
