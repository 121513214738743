.gbip-qa-rejection-panel {
  &:focus {
    outline: none;
  }
  .color-swatch {
    &.inline {
      display: inline-block;
    }
    &.circle:before {
      content: ' \25CF';
    }
    &.circle {
      font-size: 30px;
      &.red {
        color: $color--red;
      }
      &.yellow {
        color: $color--yellow;
      }
      &.blue {
        color: $color--blue;
      }
      &.black {
        color: $color--near-black;
      }
    }
    &.square {
      width: 16px;
      height: 16px;
      &.red {
        background-color: $color--red;
      }
      &.yellow {
        background-color: $color--yellow;
      }
      &.blue {
        background-color: $color--blue;
      }
      &.black {
        background-color: $color--near-black;
      }
    }
  }
  .panel__main {
    width: auto;
    .panel__content {
      width: auto;
      .gbip-panel__main {
        width: auto;
      }
    }
  }
  .rejection-panel-content {
    .reason-panel {
      font-size: 14pt;
      .reason-form {
        display: flex;
        .reason-factors,
        .reason-comments {
          flex: 1 1 0;
        }
      }
      .title {
        transform: uppercase;
        font-size: 18pt;
        font-weight: bold;
        font-family: 'Trade Gothic', sans-serif;
      }
      .description {
        color: $color--dark-gray;
        font-size: 10pt;
        padding: 15px 0px;
      }
      .defined-rejection-reasons {
        padding-bottom: 20px;
        .defined-rejection-reason {
          .color-swatch {
            position: relative;
            top: 3px;
          }
          .reason-preview-text {
            font-size: 9pt;
            padding-left: 5px;
          }
          .gbip-icon-container.gbip-icon-container--x,
          .gbip-icon-container.gbip-icon-container--edit {
            display: inline-block;
            height: 10px;
            width: 10px;
            padding-bottom: 10px;
            padding-right: 20px;
          }
        }
      }
      .reason-label {
        font-size: 10pt;
        font-weight: bold;
        padding-bottom: 5px;
        > .color-swatch {
          position: relative;
          top: 3px;
          margin-left: 10px;
        }
      }
      .reason-issue {
        > form-dropdown {
          .gbip-dropdown-container {
            margin-bottom: 5px;
          }
        }
      }
      .reason-comments {
        padding-left: 20px;
        .comment-label {
          font-size: 10pt;
          font-weight: bold;
          padding-bottom: 5px;
        }
        .comment-textarea {
          font-size: 10pt;
        }
      }
      .add-reason {
        text-align: right;
        margin-top: 20px;
        font-size: 10pt;
        .gbip-icon-container.gbip-icon-container--plus {
          display: inline-block;
          height: 10px;
          width: 10px;
          padding-bottom: 10px;
          padding-right: 20px;
        }
        .clear-selection {
          float: left;
          padding-top: 3px;
          font-weight: normal;
          text-decoration: underline;
        }
      }
      .defined-rejection-reasons {
        .defined-rejection-reason {
          .gbip-icon-container {
            margin-left: 5px;
            &.gbip-icon-container--edit {
              svg {
                transform: rotate(-90deg);
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
    .image-panel {
      position: relative;
      margin: 0px auto;
      .image-crop-set {
        float: left;
        margin-left: 12px;
        margin-right: 12px;
        .view-code-label {
          font-size: 12pt;
          font-family: 'Trade Gothic', sans-serif;
          font-weight: bold;
          padding-left: 5px;
        }
        .image {
          position: relative;
          margin: 15px 5px 25px 5px;
          &.selected {
            outline: 8px solid black;
          }
          img {
            width: 92px;
            background-color: #f7f7f7;
            border: 1px solid #d5d5d5;
          }
          .inner-image-color-swatches {
            position: absolute;
            left: 2px;
            top: 0px;
            .color-swatch {
              width: 100%;
              float: left;
              line-height: 16px;
            }
          }
        }
      }
    }
     .action-panel {
      .actions {
        margin-top: 10px;
        margin-bottom: 20px;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        .gbip-incomplete-warning {
          display: flex;
          flex: 0 1 100%;
          align-items: center;
          color: $color--red;
          text-align: left;
        }
        button {
          min-width: 100px;
          margin-left: 10px;
          flex-shrink: 0;
        }
        .add-another-reason {
          width: auto;
        }
      }
    }
 }
}
