$size--job: 40px;
$size--job--compact: 30px;
$compact-lines: 5;
// container that holds a listing of jobs
.gbip-new-job-list {
  &--compact {
    max-height: $size--job--compact * $compact-lines;
  }
  &--queue {
    @include fullsize-child();
  }
  &__shipment {
    margin: 20px 0 10px 0;
    cursor: pointer;
    .shipment-title {
      font-weight: bold;
      color: $color--dark-gray;
    }
    .shipment-jobs {
      background: $color--white;
      border: 5px solid $color--white;
      padding: 5px;
    }
    &.is-selected {
      .shipment-jobs {
        border-color: $color--dark-gray;
      }
      .shipment-title {
        color: $color--near-black;
      }
    }
  }
}

// a single job
.gbip-new-queue-job-container {
  position: relative;
  background-color: $color--white;
  border: 1px solid $color--lighter-gray;
  width: 100%;
  &:not(.is-disabled) {
    cursor: pointer;
  }
  &.is-completed {
    color: $color--completed;
  }
  &--muted {
    background-color: $color--near-white;
    color: $color--dark-gray;
  }
  &--compact {
    &:not(:first-child) {
      border-top: none;
    }
    &:nth-child(even) {
      background-color: $color--near-white;
    }
  }
  &--limited,
  &--full {
    a {
      color: inherit;
      &:hover {
        text-decoration: underline;
      }
    }
    &:not(:first-child) {
      margin-top: 5px;
    }
    &.is-selected {
      background-color: $color--near-black;
      border-color: $color--near-black;
      color: $color--white;
      a {
        color: $color--near-white;
      }
    }
  }
}

// list view
.gbip-new-queue-job {
  display: flex;
  /*height: $size--job*/
  align-items: center;
  padding: 0 $size--small-scroller 0 0px;
  &.is-inactive {
    opacity: 0.5;
    background-color: $color--mid-gray;
  }
  .no-pointer {
    cursor: default !important;
  }
  &--header {
    background-color: $color--lighter-gray;
    color: $color--dark-gray;
    height: ($size--column-gutter * 4);
    border: 1px solid transparent;
    .gbip-new-queue-job__section {
      font-weight: normal;
      &.is-active {
        color: $color--near-black;
        font-weight: bold;
        gbip-icon {
          fill: currentColor;
        }
      }
      &:not(.is-active) {
        gbip-icon {
          fill: currentColor;
          color: $color--dark-gray;
        }
      }
    }
  }
  &--compact {
    height: $size--job--compact;
  } // Job data column
  &__section {
    box-sizing: content-box;
    flex-shrink: 0;
    font-size: $font-size--small;
    line-height: $line-height--small;
    position: relative;
    padding: 0 4px;
    display: flex;
    align-items: center;
    height: 40px;
    &:first-child {
      padding-left: 10px;
    }
    gbip-icon {
      flex: 1;
    }
    confidential,
    job-type,
    priority,
    crop-types,
    .gbip-icon-container--clock {
      width: 15px;
    }
    &-filters {
      display: inline-block;
      width: 9px; //This is to space out the 2 arrows in the sort direction icon.
      &-toggle {
        & > :first-child > :first-child {
          margin-bottom: 2px;
        }
        > .inactive {
          fill: $color--dark-gray;
        }
      }
    }
    .gbip-new-queue-job--header & {
      font-weight: normal;
    }
    &.is-active {
      color: $color--near-black;
      font-weight: bold;
    }
    .gbip-new-queue-job--limited &,
    .gbip-new-queue-job--full &,
    .gbip-new-queue-job--header & {
      @include breakpoint((min-width: 1800px)) {
        padding: 0 8px;
      }
    } // Let's deal with line-breaks!
    // all columns in compact & limited view types are restricted to one line
    .gbip-new-queue-job--compact &,
    .gbip-new-queue-job--limited & {
      @include ellipsis();
    } // "wrapping" columns only do so in the full view type
    &--wrap {
      .gbip-new-queue-job--full & {
        @include line-clamp($line-height--small, 2);
      }
    } // This is convoluted but needs to be done because "ellipsis" and "line-clamp" can't overwrite each other
    .gbip-new-queue-job--full & {
      &:not(.gbip-new-queue-job__section--wrap) {
        @include ellipsis();
      }
    } // in "problem solve" view type, columns are this size unless specific specified
    &--ps {
      width: 8em;
    } // "growing" columns fill any excess space in the row
    &--grow {
      flex-grow: 1;
      flex-shrink: 1; // this effect is not allowed i nthe "problem solve" view type
      .gbip-new-queue-job--ps & {
        flex-grow: 0;
        flex-shrink: 0;
        width: 15em;
      }
    } // Specific lengths for various columns
    // Some column widths change at certain breakpoints
    &--style-color {
      font-weight: bold;
      width: 90px;
    }
    &--vendor {
      font-weight: bold;
      width: 45px;
    }
    &--name {
      width: 110px;
      font-weight: bold;
      flex-grow: 1;
      flex-shrink: 1;
      overflow: hidden;
    }
    &--season {
      font-weight: bold;
      width: 50px;
      .gbip-queue-job--compact & {
        width: 35px;
      }
    }
    &--color {
      font-weight: bold;
      width: 74px;
      .gbip-new-queue-job--compact & {
        width: 50px;
      }
      .gbip-new-queue-job--limited & {
        width: 100px;
      }
      .gbip-new-queue-job--full & {
        @include breakpoint((min-width: 1600px)) {
          width: 100px;
        }
      }

      @media (max-width: 1670px) {
        display: none;
      }
    }

    &--category {
      font-weight: bold;
      width: 74px;
      .gbip-new-queue-job--full & {
        @include breakpoint((min-width: 1600px)) {
          width: 100px;
        }
      }
    }
    &--division {
      font-weight: bold;
      width: 66px;
      .gbip-new-queue-job--full & {
        @include breakpoint((min-width: 1600px)) {
          width: 100px;
        }
      }
    }
    &--merchStatus,
    &--assetStatus {
      width: 80px;
      .gbip-new-queue-job--full & {
        @include breakpoint((min-width: 1800px)) {
          width: 100px;
        }
      }
    }
    &--icon {
      fill: currentColor;
      width: 15px;
      priority,
      confidential,
      job-type,
      rejection-indicator {
        width: 15px;
      }
    }
    img {
      max-width: 30px;
      max-height: 30px;
    }
    &--productId {
      font-weight: bold;
      width: 50px;
    }
    &--jobId {
      width: 50px;
    }
    &--shootType {
      width: 100px;
    }
    &--assignee,
    &--businessPartner {
      width: 50px;
      .gbip-new-queue-job--full & {
        @include breakpoint((min-width: 1600px)) {
          width: 100px;
        }
      }
    }
    &--assignee {
      width: 80px;
      .gbip-new-queue-job--full & {
        @include breakpoint((min-width: 1800px)) {
          width: 150px;
        }
      }
    }
    &--age {
      width: 80px;
      flex-shrink: 2;
      .gbip-new-queue-job--full & {
        @include breakpoint((min-width: 1600px)) {
          width: 100px;
        }
      }
    }
    &--view-code {
      width: 40px;
      .gbip-new-queue-job--full & {
        @include breakpoint((min-width: 1600px)) {
          width: 100px;
        }
      }
    }
    &--consumerPackageQuantity {
      width: 110px;
    }
    &--status,
    &--poId {
      width: 80px;
    }
    &--fullColorDescription {
      width: 140px;
    }
    &--colorwayCode,
    &--gender,
    &--modelId,
    &--dimension,
    &--initiator,
    &--colorwayId {
      width: 60px;
    }
  }
  &__view-code,
  &__assets {
    & + & {
      &:before {
        content: ', ';
      }
    }
  }
  &__assets {
    &.is-published {
      font-weight: bold;
    }
  }
  &__list {
    & + & {
      &:before {
        content: ' ';
      }
    }
  }
}

// asset display in post-prod queue
.gbip-job__asset-container {
  &:not(:empty) {
    margin: 0 $size--column-gutter;
    padding-bottom: $size--column-gutter;
  }
}

// thumbnails in existing job modal
.gbip-job__asset-gallery {
  margin: 13px;
}
