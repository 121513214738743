.gbip-notifications {
  position: fixed;
  left: 20px;
  top: 20px;
  z-index: 999;
}

.notification-collection {
  position: relative;
  width: static-width(3);
}

  .notification {
    align-items: center;
    background-color: $color--near-black;
    border-radius: 4px;
    filter: drop-shadow(0 2px 2px $color--near-black);
    color: $color--white;
    display: flex;
    font-size: $font-size--small;
    line-height: $line-height--small;
    margin-bottom: 10px;
    padding: 20px;
    position: relative;
    width: 100%;
    animation-duration: 500ms;
    animation-fill-mode: both;

    &__message {
      flex-grow: 1;
      margin: 0 !important;
    }

    &__close {
      position: relative;
      fill: currentColor;
      cursor: pointer;
      width: 1em;
      height: 1em;
    }
  }