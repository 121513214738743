.gbip-global-nav {
    display: flex;
    position: relative;
    align-items: stretch;
    z-index: 1;
    width: 100%;
    height: 40px;
    justify-content: space-between;
    border: 1px solid $color--light-gray;
    padding: 0 0 0 $size--column-gutter;
    * {}
    &__section {
        display: flex;
        flex: 0 1 15%;
        &.left {
            justify-content: flex-start;
        }
        &.center {
            flex: 2 1 auto;
            justify-content: center;
        }
        &.right {
            justify-content: flex-end;
        }
        &__item {
            align-items: center;
            border-bottom: 1px solid transparent;
            color: $color--dark-gray;
            display: flex;
            font-size: $font-size--small;
            justify-content: center;
            margin: 0px $size--column-gutter;
            position: relative;
            &--fill {
                flex-grow: 1;
            }
            &__queues {
                @include gbip-headline($font-size--button);
                border-top: 2px solid transparent;
                ;
                border-bottom: 4px solid transparent;
                &:hover {
                    border-bottom: 4px solid $color--mid-gray;
                }
                margin: 0px ($size--column-gutter);
                color: $color--near-black;
                &.is-active {
                    color: $color--near-black;
                    border-bottom: 4px solid $color--near-black;
                }
            }
            &__search {
                display: flex;
                border-left: 1px solid $color--mid-gray;
                border-right: 1px solid $color--mid-gray;
                 ::-webkit-search-cancel-button {
                    height: 10px;
                    width: 10px;
                    display: inline-block;
                    background: url('../../assets/svg/ui/x.svg');
                    background-size: 10px 10px;
                }
                .gbip-search__icon {
                    width: 14px;
                    height: 14px;
                    fill: black;
                    margin: 1em;
                }
                #navSearch {
                    // margin-top: 1em;
                    // margin-bottom: 1em;
                    &:focus {
                        // background: url('../../assets/svg/ui/search.svg') no-repeat scroll 10px 12px;
                        background-size: 14px 14px;
                        width: 9em;
                        margin: 0px;
                        padding: 0 1em;
                        padding-left: 0.5em;
                        transition: width .5s;
                    }
                    &:not(:focus) {
                        background: unset;
                        width: 0px;
                        transition: width .5s;
                    }
                }
            }
            &__no-search {
                border-left: 1px solid $color--mid-gray;
                height: 40px;
            }
            &--profile {
                width: 152px;
                color: $color--dark-gray;
                border-left: 1px solid $color--light-gray;
                margin: 0 0;
                .options {
                    display: none;
                    position: absolute;
                    top: 40px - 2px; //the 1px borders do not activate the hover state, so we need to make this 38 instead of 40px.
                    border: 1px solid $color--light-gray;
                }
                &:hover>.options {
                    display: block; //    display: block;
                    width: 100%;
                    background-color: $color--white;
                    color: $color--near-black;
                    display: flex;
                    flex-direction: column;
                    .option {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 40px;
                        color: $color--near-black;
                        &:not(:first-child) {
                            border-top: 1px solid $color--light-gray;
                        }
                        @include gbip-headline($font-size--body);
                        &:hover {
                            color: $color--white;
                            background-color: $color--near-black; // transition: all .2s ease;
                        }
                    }
                }
            }
        }
    }
    &__icon {
        &--icon1 {
            width: 72px;
        }
        &--menu {
            width: 24px;
            height: 14px; // prevents flickering when toggling icons
        }
        &--x {
            box-sizing: content-box;
            padding: 0 5px;
            width: 14px;
            height: 14px; // prevents flickering when toggling icons
        }
        &--disabled {
            pointer-events: none;
            cursor: default;
        }
    }
}
