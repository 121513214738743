@mixin circular-icon() {
  background-color: $color--white;
  border-radius: 50%;
}

.gbip-approval {
  @include circular-icon();

  &--rejected {
    fill: $color--red;
  }
  &--previouslyRejected {
    fill: #0075fe;
  }
}

.gbip-priority {
  @include circular-icon();

  .is-completed & {
    fill: $color--completed;
  }

  &--r {
    fill: $color--red;
  }

  &--p {
    fill: $color--yellow;
  }
}

gbip-icon#clone {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.gbip-crop-types {
  fill: $color--near-black;
  svg {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .is-selected & {
    fill: $color--white;
  }
}

.gbip-qa-approved {
  @include circular-icon();

  &--true {
    fill: $color--green;
  }

  &--false {
    fill: $color--red;
  }
}

.gbip-confidential {
  fill: $color--red;

  .is-completed & {
    fill: $color--completed;
  }
}
