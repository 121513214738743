.gbip-asset-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: -13px;
  &__item {
    padding: 10px;
    width: 100px + 20px;
    max-width: calc(33% - 6px);
    margin: 3px 3px 1.5em 3px;
    &.is-selected {
      background: $color--near-black;
    }
  }
}

.override_no_min_height {
  .gbip-asset-preview__image-container {
    min-height: auto;
  }
}

.gbip-asset-preview {
  font-size: $font-size--small;
  line-height: $line-height--small;
  position: relative;
  &__key {
    display: flex; //Flex was the simplest way to do this - inline display did not play well with the fixed width for the icons
    align-items: center;
    &-view {
    }
    &-rejected {
      background-color: $color--white;
      border-radius: 50%;
      color: $color--red;
      fill: $color--red;
      width: 14px;
    }
    &-previouslyRejected {
      background-color: $color--white;
      border-radius: 50%;
      fill: #0075fe;
      width: 14px;
    }
  }
  &__croptype {
    margin: 0 0 1em 0;
    thumbnail.primary {
    }
    thumbnail.secondary {
      display: none;
    }
    &-status {
      font-size: $font-size--sub-headline;
      font-family: $ff--brand;
      padding: 0 8px;
      font-weight: bold;
      &-icons {
        width: 14px;
        height: 14px;
        display: inline-block;
        .approve {
          fill: green;
        }
        .reject {
          fill: red;
        }
        .previouslyRejected {
          fill: #0075fe;
        }
      }
    }
  }
  &__image-container {
    background-color: $color--near-white;
    border: 1px solid $color--light-gray;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    .is-disabled & {
      background-color: $color--near-black;
    }
  }
  &__lineart-container {
    background-color: #ffffff;
    border: 1px solid $color--light-gray;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__loading {
    height: 100px;
    width: 100px;
  }
  &__image {
    max-width: 100%;
    .is-disabled & {
      background-color: $color--white;
      opacity: 0.4;
    }
    &-silhouette {
      margin: 5px;
    }
  }
  &__placeholder {
    @include ratioHeight();
    background-color: $color--near-white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__document {
    background-color: $color--near-white;
    .holder {
      display: flex;
      height: 100px;
      justify-content: center;
      align-items: center;
      gbip-icon {
        height: 50px;
        width: 50px;
      }
    }
  }
  &__annotation {
    @include absolute-position(0, 0);
    pointer-events: none;
    width: 100%;
    z-index: 1;
    > img {
      width: 100%;
    }
  }
  &__info {
    margin-top: $size--column-gutter / 6;
    display: flex;
    justify-content: space-between;
    ul {
      overflow: hidden;
    }
    &-filename {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
    }
    .is-selected & {
      color: $color--white;
    }
  }
  &__view-code {
    padding-right: 5px;
    font-weight: bold;
    font-size: $font-size--sub-headline;
    font-family: $ff--brand;
  }
  &__crop-type {
    color: $color--dark-gray; // position: absolute;
    // right: 10px;
  }
  &__status {
    font-size: $font-size--small;
    color: $color--near-black;
    .is-selected & {
      color: $color--white;
    }
    > b {
      font-weight: bold;
    }
    &-comment {
      width: 16px;
      display: inline-block;
      color: $color--dark-gray;
      fill: $color--dark-gray;
      &.right {
        float: right;
      }
    }
  }
  &__selector {
    @include absolute-position($top: -4px, $left: -4px);
  }
}

.gbip-base-product-results {
  max-height: 350px;
  overflow: auto;
  .gbip-asset-preview__image-container {
    min-height: 80px;
  }
}

.gbip-initiate-job-lineart {
  max-height: 350px;
  overflow: auto;
}

.gbip-base-product-thumbnail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  &__caption {
    margin: 0 0 0 8px;
    font-size: 12px;
    line-height: 1.3em;
    white-space: nowrap;
  }
  &__no-results {
    font-size: inherit;
    font-family: $ff--body;
    color: $color--dark-gray;
    text-align: center;
    padding: 20px 0;
  }
  &__image {
    position: relative;
    width: 80px;
    .gbip-base-job__icon {
      top: 3px;
      right: 3px;
    }
  }
}
