$size--column-gutter: 16px;
$size--columns: 12;
$size--static-column: $size--content/$size--columns;
@function static-width($span) {
    @return $size--static-column * $span;
}

@mixin static-column($span, $property:flex-basis) {
    #{$property}: static-width($span);
}

@function fluid-width($span) {
    @return percentage($span/$size--columns);
}

@mixin flex-column($span, $property:flex-basis) {
    #{$property}: fluid-width($span);
}

.gbip-container {
    margin: 0 $size--column-gutter * 2;
    min-width: 1136px;
}

.gbip-row {
    display: flex;
    margin: 0 (($size--column-gutter / 2) * -1);
    &:not(.gbip-row--no-wrap) {
        flex-wrap: wrap;
    }
    &--with-gutter {
        margin-top: $size--column-gutter;
    }

    &--with-init-gutter {
        margin-top: $size--column-gutter/2;    
    }
}

.gbip-column {
    box-sizing: border-box;
    flex-shrink: 0;
    padding: 0 $size--column-gutter/2;
    flex-grow: 1;
    &--shrink {
        flex-shrink: 1;
    }
    @for $i from 1 through $size--columns {
        &--#{$i} {
            flex-basis: fluid-width($i);
            flex-grow: 0;
        }
        &--o-#{$i} {
            margin-left: fluid-width($i);
        }
        &--s-#{$i} {
            flex-basis: static-width($i);
            flex-grow: 0;
        }
    }
}