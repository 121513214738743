.gbip-shortcut {
  display: flex;
  flex-wrap: wrap;

  &__section {
    flex: 0 0 50%;
    margin-bottom: $line-height--small;

    &--full {
      flex-basis: 100%;
    }
  }
}

  .gbip-shortcut-list {
    font-size: $font-size--small;
    line-height: $line-height--small;
    margin: $line-height--small 0;

    &__item {
      &--shortcut {
        font-weight: bold;
        padding-right: $line-height--small;
      }

      &--description {
        color: $color--dark-gray;
      }
    }
  }