.gbip-admin-section {
  margin-top: $size--column-gutter * 3;

  &--bulk-job-update,
  &--delivery-admin {
    text-align: center;
  }

  &--delivery-admin {
    .gbip-row {
      padding-top: 15px;
      align-items: center;
      justify-content: center;

      .gbip-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .gbip-input,
      form-dropdown,
      .gbip-textarea,
      .textarea-label {
        text-align: left;
        width: 600px;
      }
    }

    .gbip-column {
      margin: 0 auto;
      text-align: center;
    }

    .gbip-admin-response__item--row {
      width: 150px;
    }

    .gbip-admin-response__item--single {
      width: 100px;
      flex: 1;
    }

    ul {
      width: 600px;
      .gbip-icon {
        height: 18px;
        width: 18px;
        display: inline-block;
        padding-top: 4px;
        margin-right: 6px;
      }
      .gbip-icon-container {
        display: inline-block;
        position: relative;
        height: 18px;
        width: 18px;
        padding-bottom: 0;
      }
      span.ng-hide {
        opacity: 0;
      }
      .refresh-loading {
        gbip-icon {
          stroke: black;
        }
      }
      .refresh-success {
        gbip-icon {
          fill: $color--green;
        }
      }
      .refresh-failed {
        gbip-icon {
          fill: $color--red;
        }
      }
    }
  }

  &__cta-container {
    margin: $size--column-gutter ($size--column-gutter * 2);
    fill: $color--white;
  }

  &__user-links {
    display: inline-block;
    margin-bottom: 10px;
    width: 100%;
  }

  &__title {
    @include gbip-headline(24px);
    text-align: center;
  }

  &__subtitle {
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Trade Gothic', sans-serif;
  }

  &__description {
    color: $color--dark-gray;
    line-height: $line-height--small;
    margin-top: $size--column-gutter;
  }

  &__footnote {
    color: $color--dark-gray;
    font-size: $font-size--small;
    margin-bottom: 20px;
  }

  &__feedback {
    margin: 4em 0 0 0;
  }

  &__link {
    color: $color--dark-gray;
    font-size: $font-size--small;
    line-height: $line-height--small;
    text-decoration: underline;
  }

  textarea {
    border: 1px solid $color--near-black;
    background-color: $color--white;
    width: 100%;
    height: 7em;
    margin: 7px 0;
  }
}

.border-bottom-line {
  border-bottom: 1px solid $color--mid-gray;
  padding-bottom: 40px;
}
