.gbip-queue-no-results {
    @include center();
    color: $color--dark-gray;
    text-align: center;
}

.gbip-merch-no-results {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $color--dark-gray;
    text-align: center;
}