.gbip-sg-color {
  padding-bottom: 100%;
  position: relative;

  &--near-black {
    background-color: $color--near-black;
    &:after {
      position: absolute;
      top: 100%;
      width: 100%;
      display: block;
      text-align: center;
      content: 'near-black';
    }
  }
  &--dark-gray {
    background-color: $color--dark-gray;
    &:after {
      position: absolute;
      top: 100%;
      width: 100%;
      display: block;
      text-align: center;
      content: 'dark-gray';
    }
  }
  &--mid-gray {
    background-color: $color--mid-gray;
    &:after {
      position: absolute;
      top: 100%;
      width: 100%;
      display: block;
      text-align: center;
      content: 'mid-gray';
    }
  }
  &--light-gray {
    background-color: $color--light-gray;
    &:after {
      position: absolute;
      top: 100%;
      width: 100%;
      display: block;
      text-align: center;
      content: 'light-gray';
    }
  }
  &--lighter-gray {
    background-color: $color--lighter-gray;
    &:after {
      position: absolute;
      top: 100%;
      width: 100%;
      display: block;
      text-align: center;
      content: 'lighter-gray';
    }
  }
  &--near-white {
    background-color: $color--near-white;
    &:after {
      position: absolute;
      top: 100%;
      width: 100%;
      display: block;
      text-align: center;
      content: 'near-white';
    }
  }
  &--white {
    background-color: $color--white;
    &:after {
      position: absolute;
      top: 100%;
      width: 100%;
      display: block;
      text-align: center;
      content: 'white';
    }
  }
  &--orange {
    background-color: $color--orange;
    &:after {
      position: absolute;
      top: 100%;
      width: 100%;
      display: block;
      text-align: center;
      content: 'orange';
    }
  }
  &--red {
    background-color: $color--red;
    &:after {
      position: absolute;
      top: 100%;
      width: 100%;
      display: block;
      text-align: center;
      content: 'red';
    }
  }
}