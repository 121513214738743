.gbip-queue-tray {
  background-color: $color--near-white;
  padding: 0 $size--column-gutter * 2;
  &--expanded {
    padding-left: $size--column-gutter * 2;
    border-right: $size--column-gutter * 2 solid $color--white;
  }
}

filter-compact {
  flex: 1;
  .gbip-queue-filter {
    &__content {
      display: flex;
      align-items: flex-start;
      position: relative;
      margin-top: $size--column-gutter;
      min-height: $size--column-gutter;
      padding: 0px 39px;
      max-width: 750px;
      .dropdowns {
        display: flex;
        flex-wrap: wrap;
        .gbip-queue-filter__content {
          color: $color--dark-gray; // margin: auto;
          // width: static-width(5);
          padding-top: 10px;
          display: flex;
          flex-direction: column;
          &-filter {
            flex: 0 0 50%; //This fixes the issue with the filters being wider than their parent container.
            // How does it fix it??? Good question...
            // width: 10px;
            padding: 3px;
            &-grow {
              flex: 1;
            }
          }
        }
      }
      .buttons {
        display: flex;
        flex-direction: column;
        padding: 3px 15px 6px 6px;
        .button {
          border: 1px solid $color--lighter-gray;
          border-radius: 2px;
          padding: 8px;
          width: 40px;
          &.is-active {
            background-color: #d6d5d5;
            border-color: $color--dark-gray;
          }
          gbip-icon {
            &.button--rejectionStatus.queue\.post-prod {
              fill: $color--red;
            }
            &.button--rejectionStatus.queue\.qa {
              fill: $color--blue;
            }
          }
        }
      }
      .clearFilters {
        color: $color--dark-gray;
      }
    }
    &__header {
      justify-content: flex-end;
      padding-right: 39px;
      text-decoration: underline;
      line-height: 1;
      color: $color--near-black;
      display: flex;
      color: $color--dark-gray;
      button + button {
        margin-left: $size--column-gutter;
      }
    }
    &__checkbox {
      > button {
        width: 100%;
      }
    }
  }
}
