$details--item-spacing: 8px;

.gbip-product-detail {
  font-size: $font-size--body;

  &__main {
    margin-bottom: 1em;

    .gbip-modal & {
      margin-bottom: 2em;
    }
  }

  &__links {
    margin-bottom: 1em;
    margin-top: -0.5em;
    text-align: center;

    .gbip-product-detail-main {
      color: $color--near-black;
      font-size: 14px;
      padding: 4px 20px;
      border: 1px solid $color--orange;
      border-radius: 3px;
      display: inline-block;

      .action-link {
        text-decoration: none;
        border-bottom: 1px solid $color--near-black;
      }
    }

    /* no need to display empty elements */
    .comma:empty {
      display: none;
    }

    .comma:not(:last-child):after {
      content: ', ';
      border-bottom: 1px solid $color--white;
    }
  }

  &__refresh {
    color: $color--dark-gray;
    font-size: $font-size--small;
    line-height: $line-height--body;
    letter-spacing: 0.5px;
    position: absolute;
    left: 32px;
    margin-top: -4px;
    background-color: $color--white;
    padding-right: 10px;
    loading {
      display: inline-block;
      padding: 6px 24px;
      height: 7px;
      width: 48px;
    }
    span {
      -webkit-transition: all linear 0.5s;
      transition: all linear 0.5s;
      gbip-icon {
        height: 18px;
        width: 18px;
        display: inline-block;
        padding-top: 4px;
        margin-right: 6px;
      }
    }
    span.ng-hide {
      opacity: 0;
    }
    .refresh-loading {
      gbip-icon {
        stroke: black;
      }
    }
    .refresh-success {
      gbip-icon {
        fill: $color--green;
      }
    }
    .refresh-failed {
      gbip-icon {
        fill: $color--red;
      }
    }
  }

  &__toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;

    > button {
      box-sizing: content-box;
      fill: $color--dark-gray;
      transition: transform $timing--quick;
      width: 10px;
      padding: 1em;
      margin: -1em 0;

      &.is-open {
        transform: rotate(180deg);
      }
    }
  }

  &__full {
    margin-bottom: 1em;
  }
}

.gbip-product-detail-main {
  color: $color--dark-gray;
  font-size: $font-size--small;
  line-height: $line-height--body;
  letter-spacing: 0.5px;
  text-align: center;

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & + & {
      margin-top: 1em;
    }
  }

  &__item {
    display: flex;

    & + & {
      margin-left: $details--item-spacing;
      padding-left: $details--item-spacing;
      position: relative;

      &:before {
        @include absolute-position(50%, 0);
        background-color: $color--lighter-gray;
        content: '';
        height: $line-height--small;
        transform: translateY(-50%);
        width: 1px;
      }
    }
  }

  &__item-value {
    margin-left: $details--item-spacing;
    color: $color--near-black;
  }
}

.gbip-product-detail-full {
  display: flex;
  font-size: $font-size--small;
  letter-spacing: 0.5px;

  &__section {
    width: 25%;
  }

  &__heading {
    margin-bottom: 1em;
  }

  &__item-name {
    color: $color--dark-gray;
  }

  &__item-value {
    font-weight: normal;
  }
}

.gbip-product-detail-modal {
  .gbip-product-detail__refresh {
    margin-top: 80px;
    margin-left: 76px;
  }
  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;

    &__vendor {
      grid-column-start: 1;
      grid-column-end: span 2;
      grid-row-start: 1;
      white-space: nowrap;
    }
    &__division {
      grid-column-start: 1;
      grid-column-end: span 2;
      grid-row-start: 2;
      white-space: nowrap;
    }
    &__season {
      grid-column-start: 1;
      grid-column-end: span 2;
      grid-row-start: 4;
    }
    &__status {
      grid-column-start: 3;
      grid-row-start: 1;
    }
    &__category {
      grid-column-start: 1;
      grid-column-end: span 2;
      grid-row-start: 3;
    }
    &__age {
      grid-column-start: 3;
      grid-row-start: 2;
    }
    &__gender {
      grid-column-start: 3;
      grid-row-start: 3;
    }

    @media (max-width: 1470px) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(6, auto);

      &__vendor {
        grid-column-start: 1;
        grid-row-start: 1;
        white-space: nowrap;
      }
      &__division {
        grid-column-start: 1;
        grid-row-start: 2;
        white-space: nowrap;
      }
      &__season {
        grid-column-start: 1;
        grid-row-start: 3;
      }
      &__status {
        grid-column-start: 1;
        grid-row-start: 4;
      }
      &__category {
        grid-column-start: 1;
        grid-row-start: 5;
      }
      &__age {
        grid-column-start: 1;
        grid-row-start: auto;
      }
      &__color {
        grid-column-start: 1;
        grid-row-start: auto;
      }
      &__gender {
        grid-column-start: 1;
        grid-row-start: auto;
      }
    }
  }

  &__item-name {
    color: $color--dark-gray;
  }
}

a.action-link {
  text-decoration: underline;
}

.throb {
  animation: pulsate 2s ease-out;
  animation-iteration-count: infinite;
  z-index: 999;
}

@keyframes pulsate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
