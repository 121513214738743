/* Form Field */

::-webkit-input-placeholder {
    color: $color--light-gray;
    font-weight: normal;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: $color--light-gray;
    font-weight: normal;
}

:-ms-input-placeholder {
    color: $color--light-gray;
    font-weight: normal;
}

$form--input--padding: 0.75em;
%gbip-input {
    font-family: $ff--body;
    line-height: $line-height--small;
    background-color: #fff;
    border: 1px solid;
    border-radius: $size--corners;
    cursor: pointer;
    border-color: $color--lighter-gray;
    text-transform: none;
    width: 100%;
}

.input-always-show-text {
    .gbip-input {
        &.is-disabled {
            pointer-events: none;
            background-color: $color--disabled !important;
            color: inherit !important;
        }
    }
}

.gbip-input {
    @extend %gbip-input;
    display: flex;
    align-items: center;
    padding: 1em;
    font-size: $font-size--small;
    .gbip-input-icon {
        width: 20px;
    }
    &.is-active {
        outline: none;
        border-color: $color--dark-gray !important;
    }
    &.is-disabled {
        pointer-events: none;
        background-color: $color--disabled !important;
        color: $color--disabled !important;
    }
    &__model {
        flex-grow: 1;
        font-weight: bold;
        color: $color--near-black;
        white-space: nowrap;
        width: 100%;
    }
    // Angular validation
    &:not(.ng-untouched) {
        &.ng-invalid {
            border-color: $color--red;
        }
    }
}

.gbip-label {
    margin-right: 3px;
    white-space: nowrap;
}

.gbip-search {
    @extend %gbip-input;
    display: flex;
    align-items: center;
    font-size: $font-size--body;
    padding: $form--input--padding;
    color: $color--dark-gray;
    &.is-active {
        outline: none;
        border-color: $color--dark-gray !important;
    }
    &__icon {
        position: relative;
        width: 1em;
        height: 1em;
        fill: $color--light-gray;
        .gbip-search.is-active & {
            fill: $color--dark-gray;
        }
    }
    &__input {
        flex-grow: 1;
        margin: 0 $form--input--padding;
    }
    &__close {
        position: relative;
        width: 10px;
        height: 10px;
        fill: $color--near-black;
        outline: none;
    }
}

.gbip-dropdown {
    display: block;
    position: relative;
    &__input {
        display: flex;
        align-items: center;
    }
    &--primary,
    &--secondary,
    &--tertiary {
        @include gbip-headline($font-size--button);
    }
    &--compact {
        font-size: $font-size--small;
        font-family: $ff--body;
        line-height: $line-height--small;
    }
    &--disabled {
        pointer-events: none;
    }
    &__selection {
        width: 100%;
        font-weight: bold;
        color: $color--near-black;
        white-space: nowrap;
        .gbip-dropdown--primary & {
            color: $color--near-white;
        }
    }
    &__toggle {
        display: block;
        border: 1px solid transparent;
        border-radius: $size--corners;
        cursor: pointer;
        position: relative;
        &:focus,
        &.is-active,
        &.is-open {
            outline: none;
            border-color: $color--dark-gray !important;
        }
        &.is-open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        .gbip-dropdown--primary &,
        .gbip-dropdown--secondary &,
        .gbip-dropdown--tertiary & {
            padding: $button--size--y $button--size--x;
            padding-right: calc(#{$button--size--x} + 20px);
        }
        .gbip-dropdown--primary & {
            background-color: $color--near-black;
            border-color: $color--near-black;
            color: $color--white;
        }
        .gbip-dropdown--secondary & {
            background-color: $color--near-white;
            border-color: $color--lighter-gray;
            color: $color--near-black;
        }
        .gbip-dropdown--tertiary & {
            background-color: $color--white;
            border-color: $color--lighter-gray;
            color: $color--near-black;
        }
        .gbip-dropdown--compact & {
            background-color: $color--white;
            border-color: $color--lighter-gray;
            padding: 1em;
        }
        .gbip-dropdown--disabled & {
            border-color: $color--disabled !important;
            background-color: $color--disabled !important;
        }
        >input {
            width: 100%;
        }
    }
    &__icon {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        .gbip-dropdown--compact & {
            fill: $color--dark-gray;
        }
        .gbip-dropdown--primary &,
        .gbip-dropdown--secondary &,
        .gbip-dropdown--tertiary & {
            right: $button--size--x;
        }
        .gbip-dropdown--primary & {
            fill: $color--near-white;
        }
        .gbip-dropdown--secondary &,
        .gbip-dropdown--tertiary & {
            fill: $color--near-black;
        }
    }
    &__menu {
        background-color: $color--white;
        border: 1px solid $color--lighter-gray;
        border-top: none;
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 100%;
        z-index: 1;
        &--scroll {
            max-height: 161px;
        }
        .gbip-dropdown--compact & {
            color: $color--near-black;
        }
        .gbip-dropdown--primary & {
            border-color: $color--near-black;
        }
        .gbip-dropdown--primary &,
        .gbip-dropdown--secondary &,
        .gbip-dropdown--tertiary & {
            color: $color--near-black;
        }
        &:not(.is-open) {
            display: none;
        }
    }
    &__menu-item {
        cursor: pointer;
        padding: $form--input--padding;
        text-align: left;
        white-space: nowrap;
        &:focus {
            outline: none;
        }
        &--no-results {
            color: inherit !important;
            font-style: italic;
            font-weight: normal !important;
            pointer-events: none;
        }
        .gbip-dropdown--primary &,
        .gbip-dropdown--secondary &,
        .gbip-dropdown--tertiary & {
            padding: $button--size--y $button--size--x;
            &:not(:last-child) {
                border-bottom-width: 1px;
                border-bottom-style: solid;
            }
        }
        .gbip-dropdown--primary & {
            border-color: $color--near-black;
            &.is-selected,
            &.is-focused,
            &:hover,
            &:focus {
                background-color: $color--near-black;
                color: $color--near-white;
            }
        }
        .gbip-dropdown--secondary &,
        .gbip-dropdown--tertiary & {
            border-color: $color--lighter-gray;
            &.is-selected,
            &.is-focused,
            &:hover,
            &:focus {
                background-color: $color--near-white;
                color: inherit;
            }
        }
        .gbip-dropdown--compact & {
            border-color: transparent;
            &:empty {
                height: 32px;
            }
            &.is-focused,
            &:hover,
            &:focus {
                background-color: $color--lighter-gray !important;
            }
            &.is-selected {
                background-color: inherit;
                color: $color--near-black;
                font-weight: bold;
            }
        }
    }
}

.gbip-typeahead {
    .gbip-dropdown__icon {
        height: 100%;
        padding: 0 10px;
        width: 30px;
    }
}

.gbip-btn-list {
    @include gbip-headline($font-size--body);
    border: 1px solid;
    border-color: $color--light-gray;
    color: $color--near-black;
    text-transform: none;
    clear: both;
    &:not(.is-open) {
        display: none;
    }
    &__item {
        padding: 0.75em 0.75em;
        &:not(:last-child) {
            border-bottom: 1px solid $color--mid-gray;
        }
        &:hover,
        &:focus {
            background: $color--near-white;
            //color: $color--near-white;
        }
    }
}


/* Form field checkbox */

$gbip-checkbox--size: 1.33em;
.gbip-checkbox {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    &--view-codes {
        flex-direction: column;
    }
    &.is-disabled {
        pointer-events: none;
    }
    &:focus {
        outline: none;
    }
    [type="checkbox"] {
        display: none;
    }
    &__icon {
        background-color: $color--white;
        border: 1px solid $color--lighter-gray;
        border-radius: $size--corners;
        fill: transparent;
        height: $gbip-checkbox--size;
        padding: 3px;
        position: relative;
        width: $gbip-checkbox--size;
        .gbip-checkbox.is-disabled & {
            border-color: $color--disabled !important;
            background-color: $color--disabled !important;
        }
        .gbip-checkbox:focus & {
            border-color: $color--dark-gray;
        }
        [type="checkbox"]:checked+& {
            fill: $color--near-black;
            .gbip-checkbox:focus & {
                border-color: $color--near-black
            }
        }
    }
    &__label {
        //color: $color--mid-gray;
        cursor: pointer;
        line-height: $gbip-checkbox--size;
        .gbip-checkbox--row & {
            margin-left: 5px;
        }
        .gbip-checkbox--view-codes & {
            font-size: 10px;
            line-height: 1;
            margin-top: 5px;
        }
    }
}

.gbip-toggle {
    box-sizing: border-box;
    display: inline-block;
    color: $color--mid-gray;
    cursor: pointer;
    vertical-align: middle;
    background-color: $color--white;
    border: 1px solid;
    border-radius: 50%;
    height: 15px; // needs to be an integer for svg to render properly
    padding: 2px;
    position: relative;
    top: -1px;
    width: 15px;
    &:hover,
    &:focus {
        background-color: $color--near-black;
        color: $color--white;
        outline: none;
    }
    [type="checkbox"] {
        display: none;
    }
    &__icon {
        fill: currentColor;
        &--plus {
            [type="checkbox"]:checked~& {
                display: none;
            }
        }
        &--minus {
            [type="checkbox"]:not(:checked)~& {
                display: none;
            }
        }
    }
}

.gbip-error {
    align-items: center;
    color: $color--red;
    display: flex;
    font-size: 11px;
    line-height: 1;
    margin-bottom: 0.5em;
    &__icon {
        fill: currentColor;
        width: 12px;
        height: 12px;
    }
    &__message {
        margin-left: 5px;
    }
    &__extra-top-padding {
        padding-top: 10px;
    }
}

@mixin gbip-textarea($min-lines: 2, $max-lines: 4) {
    background-color: #fff;
    border: 1px solid $color--lighter-gray;
    line-height: $line-height--body;
    height: calc(#{$line-height--body * $min-lines} + 11px);
    max-height: calc(#{$line-height--body * $max-lines} + 11px);
    padding: 5px 10px;
    width: 100%;
    &:focus {
        border-color: $color--dark-gray;
    }
}

.gbip-textarea {
    &__input {
        @include gbip-textarea(4, 8);
    }

    &.textareaAnimate {
        textarea {
            height: 3em;
            transition: ease-in-out, height 200ms ease-in-out;
        }

        textarea:hover {
            height: 5em;
        }

        textarea:focus {
            height: 7em;
        }
    }
}
