.gbip-product-jobs {
  margin: 0;

  &__job {
    padding: 15px;
    width: 100%;
    background-color: $color--near-white;
    margin-bottom: 10px;
    margin-top: 10px;
    transition: background-color 650ms;
    display: flex;
    flex-wrap: wrap;

    justify-content: space-between;

    &.collapsed {
      &:hover {
        background-color: $color--mid-gray;
      }
    }

    .label {
      font-family: $ff--brand;
      font-weight: bold;
    }
  }

  &__job:hover {
    .action-link {
      display: inline !important;
    }
  }

  &__heading {
    padding: 25px 15px 0;
    display: flex;
    flex-wrap: wrap;
    font-weight: bold;
    font-size: $font-size--sub-headline;
    justify-content: space-between;
    width: 100%;
  }

  .job {
    display: flex;

    &--id {
      width: 80px;
    }

    &--type {
      width: 80px;
    }

    &--studio {
      width: 100px;
    }

    &--business-partner {
      width: 140px;
    }

    &--merch-type {
      width: 100px;
    }

    &--shoot-type {
      width: 100px;
    }

    &--views {
      width: 60px;
    }

    &--last-modified {
      width: 140px;
    }

    &--status {
      width: 140px;

      .gbip-icon {
        height: 18px;
        width: 18px;
        display: inline-block;
        padding-top: 4px;
        margin-right: 6px;
      }

      .success {
        fill: $color--green;
      }

      .failure {
        fill: $color--red;
      }
    }
  }
}
