.gbip-queue-toggle {
  background-color: $color--near-white;
  height: 100%;
  position: relative;

  &__button {
    width: 26px;
    height: 26px;
    position: absolute;
    left: 100%;
    top: 20px;
    transform: translateX(-50%);
    fill: $color--near-black;
    background-color: $color--white;
    border-radius: 50%;
  }
}