.gbip-base-product-selector {
  &__header {
    text-align: center;
    margin-bottom: 20px;

    h2 {
      margin-bottom: 0;
      text-transform: uppercase;
    }

      p {
          font-size: 10px;
          line-height: 0.7em;
          color: $color--dark-gray;
          font-weight: normal;
        }
  }

  &__search-label {
    font: bold $font-size--small $ff--body;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  &__products-container {
    border: 1px solid $color--lighter-gray;
    border-top: 0px;
    min-height: 100px; // loader height + padding
    padding: 25px;
    position: relative;

    .gbip-asset-hero__nav--back {
      left: 15px;
    }

    .gbip-asset-hero__nav--forward {
     right: 15px;
    }
  }
}

.gbip-horizontal-job-list {
  width: 370px;
  overflow: hidden;
  margin: auto;

  &--scroller {
    display: flex;
    flex-wrap: nowrap;
    transition: margin $timing--default;

    &-item {
      flex: 0 0 120px;
    }
  }
}

.gbip-base-job {
  position: relative;
  padding: $size--column-gutter / 2;

  &.is-selected {
    background-color: $color--near-black;
    border-color: $color--near-black;
    color: $color--white;
  }

  &__section {
    font-size: 10px;
    line-height: $line-height--small;

    & + & {
      margin-top: $size--column-gutter / 2;
    }

    &--product-info {
      font-weight: bold;
    }
  }

    &__item {
      &--wrap {
        @include line-clamp($line-height--small, 2);
      }
    }

    &__icon {
      @include absolute-position($top: -6px, $right: 6px);
      background-color: $color--white;
      border: 1px solid $color--white;
      border-radius: 3px;
      width: $size--column-gutter;
      height: $size--column-gutter;
    }
}
