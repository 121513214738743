/* Customize the label (the container) */
.radiobutton-container {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .radiobutton {
      position: absolute;
      top: 2px;
      left: 0;
      height: 16px;
      width: 16px;
      background-color: white;
      border: 1px #cccccc solid;
      border-radius: 50%;

      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 3px;
        left: 3px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #333333;
      }
    }

    input:checked ~ .radiobutton {
      background-color: white;
      &:after {
        display: block;
      }
    }
  }