.gbip-detail-actions {
  @include absolute-position(
    $top: $size--content-gutter,
    $left: $size--content-gutter
  );
  align-items: center;
  display: flex;
  list-style-type: none;
  z-index: 1;
  &__item {
    width: $size--column-gutter;
    &--problem-solve {
      fill: $color--red;
    }
    & + & {
      margin-left: $size--column-gutter;
    }
    button {
      width: 100%;
    }
  }
}

.gbip-detail-close {
  @include absolute-position(
    $top: $size--content-gutter,
    $right: $size--content-gutter
  );
  fill: $color--dark-gray;
  transition: fill $timing--quick;
  width: 25px;
  z-index: 1;
  &:hover,
  &:focus {
    fill: $color--near-black;
  }
}
