.gbip-request-code {
  text-align: center;
  &__header {
    margin: 40px 0;
    text-align: center;
  }
  &__main {
    text-align: center;
  }
  &__code {
    width: 260px;
    padding: 15px;
    height: 50px;
    margin: 0 auto 10px;
  }
  &__code:hover {
    color: $color--light-blue;
    fill: $color--light-blue;
  }
  &__naming-guide {
    margin-top: 40px;
    height: 80%;
    width: 80%;
  }
}
