$size--nub: 5px;

.gbip-tooltip {
  background-color: $color--near-black;
  border-radius: $size--corners;
  color: $color--white;
  display: inline-block;
  font-size: $font-size--small;
  font-family: $ff--body;
  line-height: 1;
  padding: $line-height--small;
  pointer-events: none;
  transition: opacity $timing--quick, transform $timing--quick;
  white-space: nowrap;
  z-index: 999;

  &:not(.is-active) {
    opacity: 0;
  }

  &--top {
    @include put-an-arrow-on-it($size--nub, $color--near-black);
    transform: translate(-50%, 0);
    margin-bottom: $size--nub * 2;

    &:not(.is-active) {
      transform: translate(-50%, 10%);
    }
  }

  &--bottom {
    @include put-an-arrow-on-it($size--nub, $color--near-black, top);
    transform: translate(-50%, 0);
    margin-top: $size--nub * 2;

    &:not(.is-active) {
      transform: translate(-50%, -10%);
    }
  }
}