.gbip-sg {
  &__header {
    background-color: $color--near-black;
    color: $color--white;
    text-align: center;
  }

  &__headline {
    @include gbip-headline(64px);
    padding: 2em;
  }

  &__article {
    margin-bottom: 6em;
  }

  p,
  pre {
    margin-bottom: $line-height--body;
  }
}