.gbip-print {
  // @include absolute-position($top:0,$left:0);
  // background-color: #fff;
  // width: 100%;

  &__shipping-info {
    page-break-inside: avoid;

    .inventory-sheet {
      margin: 50px auto 0 auto;
      width: 60%;
      max-width: 800px;
    }

    .content {
      width: 90%;
    }
  }

  &__print-wrapper {
    border: 1px solid $color--mid-gray;
    padding: 20px 40px;

    .gbip-queue-job-container--compact:not(:first-child) {
      border-top: 1px solid $color--mid-gray;
    }
  }
}
