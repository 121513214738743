.h-lsn {
  list-style: none;
}

.h-tdu {
  text-decoration: underline;
}

.h-hidden-link {
  color: inherit;
  text-decoration: none;
}

.h-hidden-input {
  outline: none;
  width: 100%;
}

.h-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.h-vh {
  visibility: hidden;
}

.h-near-black {
  color: $color--near-black;
}
.h-dark-gray {
  color: $color--dark-gray;
}
.h-mid-gray {
  fill: $color--mid-gray;
  color: $color--mid-gray;
}
.h-light-gray {
  color: $color--light-gray;
}
.h-lighter-gray {
  color: $color--lighter-gray;
}
.h-near-white {
  color: $color--near-white;
}
.h-white {
  color: $color--white;
}
.h-orange {
  color: $color--orange;
}
.h-red {
  color: $color--red;
}
.h-green {
  fill: $color--green;
  color: $color--green;
}

.h-bold {
  font-weight: bold;
}
.h-uppercase {
  text-transform: uppercase;
}

.bg-h-light-gray {
  background-color: $color--light-gray;
}

.icon-inline {
  display: inline-block;
  vertical-align: middle;

  &--16 {
    height: 16px;
    width: 16px;
    .gbip-icon-container {
      height: 16px;
      width: 16px;
    }
  }

  &--20 {
    height: 20px;
    width: 20px;
    .gbip-icon-container {
      height: 20px;
      width: 20px;
    }
  }

  &--24 {
    height: 24px;
    width: 24px;
    .gbip-icon-container {
      height: 24px;
      width: 24px;
    }
  }
}

.icon-right {
  margin-left: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-fade-in {
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.hover-fade-in:hover {
  opacity: 1;
}

.icon-hover-blue:hover {
  fill: $color--light-blue;
  path: $color--light-blue;
}
