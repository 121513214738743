$ff--brand: 'Trade Gothic', sans-serif;
$ff--body: Helvetica, sans-serif;
$font-size--headline: 32px;
$font-size--sub-headline: 16px;
$font-size--body: 14px;
$font-size--button: 15px;
$font-size--small: 12px;
$line-height--body: 1.7em;
$line-height--small: 1.2em;
$line-height--headline: 1em;
$color--near-black: #111111;
$color--dark-gray: #8d8d8d;
$color--mid-gray: #cccccc;
$color--light-gray: #dddddd;
$color--lighter-gray: #e5e5e5;
$color--near-white: #f7f7f7;
$color--white: #ffffff;
$color--orange: #fa5400;
$color--red: #fe0000;
$color--green: #7bba3c;
$color--yellow: #ffd400;
$color--blue: #0009ff;
$color--light-blue: #6da5df;
$color--grey-border: #d5d5d5;
$color--completed: $color--mid-gray;
$color--disabled: $color--mid-gray;
$size--content: 1280px;
$size--content-gutter: 32px;
$size--corners: 2px;
$height--content: calc(100vh - 41px); // subtract height of nav
$timing--default: 300ms;
$timing--quick: $timing--default / 2;
$timing--long: $timing--default * 2;
$thumbnail--size: 100px;
