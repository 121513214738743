.gbip-image-viewer {
    &__hero {

      .zoom-in {
        cursor:  zoom-in;
      }

      .zoom-out {
        cursor:  zoom-out;
      }

    }
      &__magnify {
        &.not-selectable {
         user-select: none;
          pointer-events: none;
        }
        border: 1px solid #FFFFFF;
        background: rgba(255,255,255, .5);
        position: absolute;
        z-index: 1;
      }

    &__zoom {
      @include absolute-position(0, calc(100% + #{$size--column-gutter}));
      width: 100%;
      height: 100%;

      .gbip-annotation & {
        @include absolute-position(0, calc(100% + #{$size--column-gutter*2}));
        width: calc(100% + #{$size--column-gutter*2});
      }
      
      background: $color--white;
      z-index: 1;
      overflow: hidden;
      opacity: 0;
      transition: opacity $timing--quick;

      &.fadeIn {
        opacity:  1;
      }

      &-display {
        z-index: 220;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background: $color--near-white;
        img {
          position:absolute;
        }
      }
    }

    &__loading {
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      transform: scale(0.2);
      object-fit: scale-down;
    }
  }
