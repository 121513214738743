.gbip-loading {
  @include fullsize-child();
  z-index: 9;

  &--fixed {
    position: fixed;
  }

  &--white-less-transparent {
    background-color: rgba($color--white, 0.9);
  }

  &--white {
    background-color: rgba($color--white, 0.6);
  }

  &--near-white {
    background-color: rgba($color--near-white, 0.6);
  }

  &__icon {
    @include center();
    pointer-events: none;
    color: rgba($color--near-black, 0.4);

    &--near-bottom {
      top: auto;
      bottom: 150px;
    }
  }

  &__label {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -60%);
  }
}
