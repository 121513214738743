.gbip-job-detail {
  &__section {
    background-color: $color--white;
    padding: $size--column-gutter;
  }
  &-create {
    text-align: center;
    margin: 0 auto;
    height: 100%;
    &__actions {
      display: flex;
      justify-content: space-around;
      background-color: #fff;
      height: 100%;
      align-items: center;
    }
    &__header {
      font-size: 1.2em;
      color: #000;
      font-family: $ff--brand;
      text-transform: uppercase;
      font-weight: bold;
      margin: 1em 0;
    }
    &__button {
      height: 40px;
      margin: 0 0 1em 0;
    }
    &__icon {
      width: 40px;
      height: 40px;
      padding: 8px;
      border: 1px $color--mid-gray solid;
      fill: $color--mid-gray;
      &.active {
        fill: $color--red;
        border: 1px $color--red solid;
      }
    }
    &__instruction {
      margin: 0 0 1em 0;
      color: $color--mid-gray;
      text-align: center;
    }
    .gbip-fileupload-group__header {
      font-size: 1.2em;
      text-align: left;
    }
  }
  &__image-viewer,
  &__image-viewer-wrapper {
    position: relative;
    .gbip-image-viewer__image {
      border-width: 1px;
    }
  }

  &__photos {
    flex-grow: 1;
    min-width: 50%;
  }

  &__tabs {
    max-width: 750px;
    flex-grow: 0;
  }
}

.gbip-fileupload-group {
  .img-hero {
    position: relative;
    height: auto;
    width: 100%;
    margin: 0 auto;
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 35px;
    }
    .gbip-detail-close-hero,
    .gbip-detail-download-hero {
      position: absolute;
      top: 15px;
    }
    .gbip-detail-download-hero {
      top: 50px;
    }
    .gbip-detail-close-hero,
    .gbip-detail-download-hero {
      position: absolute;
      right: 15px;
      width: 20px;
      height: 20px;
      margin: 0 auto;
      .gbip-icon-container {
        display: block;
        position: absolute;
        height: 0;
        right: 15px;
        top: 15px;
        width: 20px;
        padding-bottom: 20px;
      }
    }
    .gbip-detail-download-hero {
      top: 50px;
    }
    > img {
      width: 100%;
      height: auto;

    }
  }
}
