.gbip-qa-actions-icons {
  &__item {
    width: $size--column-gutter;
    fill: $color--near-black;

    display: inline-flex;
    position:relative;
    top: calc(50% - 8px);

    &:hover {
      filter: none;
      fill: $color--dark-gray;
    }

    &--problem-solve {
      fill: $color--red;

      &:hover {
        filter: (brightness(0.8));
        fill: $color--red;
      }
    }

    &--toggle-crop-types {
      svg {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    & + & {
      margin-left: $size--column-gutter;
    }

    button {
      width: 100%;
    }
  }
}
