.gbip-queue__header {
  text-align: center;
  padding: 40px 40px 0;
  flex-shrink: 0;

  p {
  	color: $color--mid-gray;
  	margin-top: 40px;
  }
}
