.gbip-photography-detail {
  &__section {
    padding-bottom: $size--column-gutter;

    & + & {
      border-top: 1px solid $color--lighter-gray;
      padding-top: $size--column-gutter;
    }
  }

  &__header {
    margin-bottom: $size--column-gutter;
  }

  &__error {
    color: $color--red;
    margin-bottom: 10px;
  }

  &__download-modal {
    h1 {
      padding-bottom: 5px;
    }

    .gray {
      color: $color--dark-gray;
    }

    &__section {
      margin-bottom: 10px;

      .radiobutton-container {
        font-size: 12px;
      }
    }

  }

  &__download-modal-results {
    text-align: center;
    margin: 20px;
  }

  .gbip-asset-preview__image-container {
    max-width: 100px;
  }
}

.gbip-shoot-details {
  display: flex;
  position: relative;
  margin: 0 0 8px 0;

  &__section {
    &--cta {
      margin-left: 8px;
      width: 12px;

      button {
        width: 12px;
      }
    }
  }
}

.gbip-shoot-info {
  font-size: $font-size--small;
  letter-spacing: 0.5px;
  flex-grow: 2;
}

.gbip-edit-shoot-info {
  color: $color--near-black;
  font-size: $font-size--small;
  line-height: $line-height--small;
  letter-spacing: 0.5px;
  position: relative;
  padding: 8px !important;

  &__section {
    & + & {
      margin-top: $size--column-gutter;
    }

    &--views {
      align-items: flex-end;
      display: flex;
      width: 100%;
    }
  }

  &__sub-section {
    &--views {
      flex-basis: 66%;
    }

    &--actions {
      margin-left: auto;
    }
  }

  &__header {
    margin: 5px 8px 0 5px;
    white-space: nowrap;
  }

  &__view-codes {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;
    transition: ease-in-out, margin 200ms ease-in-out;
  }

  &__view-code {
    margin-right: $size--column-gutter/2;
    margin-bottom: 1em;

    &-icon {
      width: 10px;
      color: $color--dark-gray;
      position: absolute;
      bottom: 5px;
      margin-left: -7.5px;
      left: 50%;
      display: none;
    }
  }

  &__view-code-toggle {
    border: 1px $color--dark-gray solid;
    border-radius: 2px;
    padding: 2px 6px;
    min-width: 22px;
    line-height: 16px;
    text-align: center;
    background-color: $color--dark-gray;
    color: $color--white;

    &.selected {
      border: 1px #000 solid;
      background-color: $color--white;
      color: #000;
    }

    &.disabled {
      border: 1px $color--lighter-gray solid;
      background-color: $color--lighter-gray;
      color: #fff;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    & + & {
      margin-left: $size--column-gutter/2;
    }
  }
}

.gbip-asset-download {
  display: flex;
  justify-content: flex-end;
}
