.gbip-animation-expand {
  overflow: hidden;
  transition: height $timing--default;
  
  &--quick {
    transition: height $timing--quick;
  }

  &.is-open {
    overflow: visible;
  }

  &.is-animating {
   display: block !important;
  }
}

