@mixin activity-body-text {
  color: $color--dark-gray;
  font-family: $ff--body;
  font-size: $font-size--small;
  font-weight: normal;
  text-transform: none;
}

.gbip-activity {

  .gbip-activity__seasons {
    background-color: $color--near-white;
    min-height: 265px;

    .gbip-loading {
      height: 265px;
    }

    & > .gbip-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0;
      padding: 20px 38px;
      position: relative;
      width: 100%;

      .gbip-season-box {
        background-color: $color--white;
        border-top: 1px solid $color--light-gray;
        border-right: 1px solid $color--light-gray;
        border-bottom: 1px solid $color--light-gray;
        height: 225px;
        flex-basis: 0;
        flex-grow: 1;
        position: relative;

        &:first-of-type {
          border-left: 1px solid $color--light-gray;
        }

        .gbip-season-box__inner {
          height: 100%;
          position: absolute;
          width: 100%;
        }

        a {
          color: $color--near-black;
        }

        .gbip-season-box__row {
          display: block;
          position: relative;

          .gbip-season-box__season {
            float: left;
          }

          .gbip-season-box__date-goal {
            float: right;
          }
        }

        .gbip-season-box__season {
          @include absolute-position($top: 10px, $left: 10px);
          text-align: left;
        }

        .gbip-season-box__date-goal {
          @include absolute-position($top: 10px, $right: 10px);
          @include activity-body-text();
          line-height: 1;
          text-align: right;
        }

        .gbip-season-box__value {
          @include gbip-headline(45px);
          margin-top: 68px;
          text-align: center;
        }

        .gbip-season-box__diff {
          color: $color--dark-gray;
          text-align: center;
        }

        .gbip-season-box__nd-container {
          @include absolute-position($bottom: 15px);
          text-align: center;
          width: 100%;

          .gbip-season-box__nd {
            &:nth-of-type(1) {
              float: left;
              margin-left: 15%;
            }

            &:nth-of-type(2) {
              float: right;
              margin-right: 15%;
            }

            .gbip-season-box__nd-value {
              @include gbip-headline(28px);
            }

            .gbip-season-box__nd-label {
              @include activity-body-text();
            }
          }
        }

      }

    }

  }

  .gbip-activity-filters {

    .gbip-activity-filter__dropped {

      .gbip-filter__body {
        padding-bottom: 15px;
      }

      .gbip-queue-job-container--full:not(:first-child) {
        margin-top: 10px;
      }
    }

    .gbip-activity-filter__reactivate {

      .gbip-reactivate-job {
        border-top: 1px solid $color--mid-gray;
        padding-top: 15px;
      }

    }

    .gbip-filter__group {
      background-color: $color--near-white;
      border: none;

      .gbip-filter__header {

        h2 {
          @include gbip-headline(24px);
          padding: 17px 0 15px 12px;
        }

        p {
          @include activity-body-text();
          display: none;
          padding-bottom: 9px;
          padding-left: 12px;
        }
      }

      &.is-open .gbip-filter__header {

        h2 {
          padding-bottom: 5px;
        }

        p {
          display: block;
        }

      }

      .gbip-filter__body {

        .gbip-filter-job-list__container {
          position: relative;

          &.loading {
            min-height: 215px;
          }

          .gbip-loading {
            // min-height: 215px;
          }

          .gbip-queue-job__section--input {
            display: none;
          }

          .gbip-queue-job--header {
            border-left: none;
            border-right: none;
          }

        }

        .gbip-button--secondary {
          background-color: #ddd;
        }

        .gbip-job__buttons {
          margin: 15px 0 0 0;
          padding-bottom: 15px;
          text-align: right;

          button:first-of-type {
            margin-right: 7px;
          }
        }
      }

      .gbip-activity-filters__inner {
        padding: 0 10px;
      }
    }

    .gbip-activity-filter-uservssystem {

      .gbip-filter-job-list__container {
        padding-bottom: 20px;
      }

      .gbip-queue-job--header {
        border-bottom: 1px solid $color--mid-gray;
        border-top: 1px solid $color--mid-gray;
        // padding-left: 10px;
      }

      .gbip-job-uservssystem {

        .gbip-job-uservssystem__subhead {
          @include activity-body-text();
          padding-left: 8px;

          &.gbip-job-uservssystem__subhead-user {
            padding-top: 9px;
            padding-bottom: 2px;
          }

          &.gbip-job-uservssystem__subhead-system {
            padding-top: 3px;
            padding-bottom: 2px;
          }
        }

        .gbip-job-row[ng-click], .gbip-job-row[ng-dblclick] {
          cursor: default;
        }

        .gbip-job-uservssystem__buttons {
          margin: 20px 0 8px 0;
          text-align: right;

          button:first-of-type {
            margin-right: 7px;
          }
        }
      }

    }

  }

  .gbip-last-updated {
    @include activity-body-text();
    margin: 5px 0 15px 0;
    padding-right: 38px;
    text-align: right;

    &.failure {
      font-weight: bold;
      color: $color--red;
    }
  }

  .gbip-queue-main {
    margin: 0 $size--column-gutter * 2.5;

    &--expanded {
      min-width: $size--content;
    }

    &__column {
      .gbip-queue-main--expanded & {
        @include flex-column(10);
        @include flex-column(1, margin-left);
        flex-grow: 0;
      }
    }

    &__body {
      height: $height--content;
    }
  }

  .gbip-modal {

    .gbip-queue-job-container--compact:nth-child(even) {
      background-color: inherit;
    }

    .gbip-queue-job-container--compact:not(:first-child) {
      border-top: 1px solid $color--mid-gray;
    }

  }
}
