.gbip-attachments {
  margin-top: $size--column-gutter;

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: $size--column-gutter * 5;
  }

    &__item {
      align-items: center;
      display: flex;
      font-size: $font-size--small;
    }

      &__file {
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      &__delete {
        width: 8px;
        height: 8px;
        fill: $color--dark-gray;
        margin-left: $size--column-gutter;

        &:hover,
        &:focus {
          fill: $color--near-black;
        }
      }

  &__actions {
    display: flex;
    justify-content: flex-start;
    color: $color--dark-gray;
    text-decoration: underline;

    * + * {
      margin-left: $size--column-gutter/2;
    }
  }
}