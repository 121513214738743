$size--accordion-padding: 1em;

.gbip-accordion {
  &__group {
    border-top: 1px solid $color--lighter-gray;
    color: $color--near-black;
  }

  &__header {
    background: url('/assets/svg/ui/plus.svg') center right no-repeat;
    background-size: 7px;
    padding: $size--accordion-padding 0;
    margin-right: $size--accordion-padding/2;
    outline: none;
    cursor: pointer;

    &[aria-expanded="true"] {
      background-image: url('/assets/svg/ui/minus.svg');
    }
  }

  &__body {
    margin-bottom: $size--accordion-padding;

    &:not(.is-open) {
      display: none;
    }
  }
}