$size--scroller: 20px;
$size--small-scroller: 10px;

.gbip-scroll {
  &--y {
    overflow-y: auto;
  }

  &::-webkit-scrollbar {
    width: $size--scroller;
  }

  &::-webkit-scrollbar-track {
    background-color: $color--lighter-gray;
  }

  &--small {
    &::-webkit-scrollbar {
      width: $size--small-scroller;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color--mid-gray;
    border-radius: 8px;
  }
}

%iScrollScrollbar {
  background-color: $color--lighter-gray;
  position: absolute;
  z-index: 1;

  .iscroll--no-scroll & { // display: none will cause rendering errors
    pointer-events: none;
    visibility: hidden;
  }

  .iScrollIndicator {
    background-color: $color--mid-gray;
    position: absolute; 
    border-radius: 8px;
  }
}

.iScrollVerticalScrollbar {
  @extend %iScrollScrollbar;
  width: $size--scroller;
  bottom: 0; 
  top: 0; 
  right: 0;

  .iscroll--small-scroller & {
    width: $size--small-scroller;
  }

  .iScrollIndicator {
    width: 100%; 
  }
}

.iScrollHorizontalScrollbar {
  @extend %iScrollScrollbar;
  height: $size--scroller;
  left: 0;
  right: 0;
  top: 0;

  .iscroll--small-scroller & {
    height: $size--small-scroller;
  }

  .iScrollIndicator {
    height: 100%;
  }
}