.iscroll {
  overflow: hidden;
  position: relative;
  width: 100%;

  &--y {
    height: 100%;
  }

  &__wrapper {
    position: relative;
    
    .iscroll--x & {
      display: inline-block;
      height: 100%;
      min-width: 100%;
    }

    .iscroll--y & {
      min-height: 100%;
      width: 100%;
    }
  }
}
