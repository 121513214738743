.gbip-thumbnail {
    font-size: $font-size--small;
    line-height: $line-height--small;
    >img {
        background-color: $color--near-white;
        max-width: 100%;
    }
    &__placeholder {
        @include ratioHeight();
        background-color: $color--near-white;
    }
    &__view-code {
        color: $color--dark-gray;
        margin-top: 10px;
        >b {
            color: $color--near-black;
            font-weight: normal;
        }
    }
    &__status {
        margin-top: 0.25em;
        color: $color--near-black;
        >b {
            font-weight: bold;
        }
    }
}