// Libraries
$form-element-min-height: auto;
@import 'sanitize.css/lib/sanitize.scss';
@import 'utilities/_mixins.scss';
@import 'utilities/_angular.scss';
@import 'utilities/_svg.scss';
@import 'utilities/_loading.scss';
@import 'global/_vars.scss';
@import 'global/_helpers.scss';
@import 'global/_fonts.scss';
@import 'global/_headlines.scss';
@import 'global/_accordion.scss';
@import 'global/_progressBar.scss';
@import 'global/_grid.scss';
@import 'global/_buttons.scss';
@import 'global/_modal.scss';
@import 'global/_forms.scss';
@import 'global/_zindex.scss';
@import 'global/_header.scss';
@import 'global/_icons.scss';
@import 'global/_scrollers.scss';
@import 'global/_viewOptions.scss';
@import 'global/_badge.scss';
@import 'global/_loading.scss';
@import 'global/_tooltip.scss';
@import 'global/_shortcuts.scss';
@import 'global/_thumbnail.scss';
@import 'global/_note.scss';
@import 'global/_sort.scss';
@import 'global/_animate.scss';
@import 'global/_fileupload.scss';
@import 'search/assetDownload.scss';
@import 'vendor/_animate.scss';
@import 'vendor/_modal.scss';
@import 'vendor/_iscroll.scss';
@import 'vendor/_notification.scss';
@import 'styleguide/_scaffolding.scss';
@import 'styleguide/_article.scss';
@import 'styleguide/_grid.scss';
@import 'styleguide/_color.scss';
@import 'styleguide/_buttons.scss';
@import 'styleguide/_forms.scss';
@import 'styleguide/_iconography.scss';
@import 'styleguide/_iscroll.scss';
@import 'header/_globalNav.scss';
@import 'header/_roleNav.scss';
@import 'header/_logo.scss';
@import 'admin/_structure.scss';
@import 'admin/_section.scss';
@import 'admin/_response.scss';
@import 'admin/_studios.scss';
@import 'admin/_productSync.scss';
@import 'search/_structure.scss';
@import 'search/_filter.scss';
@import 'search/_tag.scss';
@import 'search/_results.scss';
@import 'search/_instructions.scss';
@import 'search/_compare.scss';
@import 'search/_terms.scss';
@import 'job/_job.scss';
@import 'job/_newJob.scss';
@import 'job/_download.scss';
@import 'job/_baseProductSelector.scss';
@import 'qa/_actions.scss';
@import 'qa/_imageViewer.scss';
@import 'qa/_outreachViewer.scss';
@import 'qa/_imageZoom.scss';
@import 'qa/_annotation.scss';
@import 'qa/_rejection.scss';
@import 'qa/_rejectionPanel.scss';
@import 'merch/_structure.scss';
@import 'detail/_structure.scss';
@import 'detail/_header.scss';
@import 'detail/_productDetail.scss';
@import 'detail/_actions.scss';
@import 'detail/_bestAsset.scss';
@import 'detail/_removeBestAsset.scss';
@import 'detail/_deliver.scss';
@import 'detail/_productDelivery.scss';
@import 'detail/_productJobs.scss';
@import 'detail/_merchStatus.scss';
@import 'detail/_photographyDetail.scss';
@import 'detail/_assetGallery.scss';
@import 'detail/_tabs.scss';
@import 'detail/_attachments.scss';
@import 'detail/_history.scss';
@import 'detail/_jobDetail.scss';
@import 'detail/_assetJobDetail.scss';
@import 'detail/_notes.scss';
@import 'detail/_qa.scss';
@import 'detail/_downloadModal.scss';
@import 'queue/_vars.scss';
@import 'queue/_structure.scss';
@import 'queue/_tray.scss';
@import 'queue/_main.scss';
@import 'queue/_header.scss';
@import 'queue/_meta.scss';
@import 'queue/_modal.scss';
@import 'queue/_slate.scss';
@import 'queue/_toggle.scss';
@import 'queue/_nav.scss';
@import 'queue/_problem.scss';
@import 'queue/_noResults.scss';
@import 'queue/_results.scss';
@import 'resolve/_structure.scss';
@import 'print-slate/_structure.scss';
@import 'shipping-info/_structure.scss';
@import 'request_code/_structure.scss';
@import 'activity/_structure.scss';
@import 'initiateJob/_structure.scss';
@import 'component/radiobutton.scss';
@import 'component/csvDownload.scss';

:root {
  color: $color--near-black;
  font-size: $font-size--body;
  font-family: $ff--body;
  line-height: $line-height--body;
  overflow: auto;
  min-width: $size--content + ($size--column-gutter * 5);
}

// Override sanitze.css
svg {
  fill: inherit;
}

button,
input,
textarea {
  &:hover,
  &:focus {
    outline: none;
  }
}

button {
  text-align: inherit;
}

body {
  &.modal-is-active {
    height: 100vh;
    overflow: hidden;
  }
}

.gbip-face {
  width: 200px;
  cursor: grab;
  position: fixed;
  z-index: 9;
  &:active {
    cursor: grabbing;
  }
  > img {
    pointer-events: none;
    width: 100%;
    user-select: none;
  }
}
