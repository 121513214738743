.gbip-search-results {
    &__section {
        &--nav {
            margin-bottom: 5px;
        }
    }
    &__iscroll-container {}
}

.gbip-search-results-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    &__section {
        &:not(:last-child) {
            margin-right: 10px;
        }
        &--tags {
            flex-grow: 1;
        }
        &--download {
            line-height: 1;
        }
        &--create-job {
            margin-right: 0 !important;
        }
    }
    &__download {
        width: 20px;
    }
}

.gbip-search-instructions__copy {
    color: $color--dark-gray;
}