.gbip-csv-download {
  &__actions {
    display: flex;
    > div {
      margin-left: 10px;
    }
  }
  &__button {
    &-el {
      background-color: inherit;
    }
    .gbip-icon-container {
      width: 15px;
      height: 15px;
    }
    &-loading {
      width: 15px;
      height: 15px;
    }
  }
}
