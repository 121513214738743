.gbip-remove-best-asset {
  h2 {
    text-transform: uppercase;
    font-weight: bold;
    padding: 0px 0 15px;
    font-family: 'Trade Gothic', sans-serif;
    font-size: 18px;
    padding-bottom: 0px;
  }
  &__subtitle {
    padding-top: 0;
    font-size: 12px;
    color: $color--dark-gray;
    padding-bottom: 10px;
  }
  &__top-nav {
    display: flex;
    &__header {
      flex-grow: 1;
    }
  }
  .gbip-asset-gallery {
    padding-top: 10px;
    .gbip-asset-gallery__item {
      width: auto;
      padding: 0;
      margin: 2px 2px 20px 2px;
      .gbip-asset-preview__view-code {
        margin-left: 10px;
      }
    }
    .gbip-asset-gallery__item-group {
      padding: 5px;
      &:hover {
        background-color: $color--light-gray;
      }
      &.selected{
        background-color: $color--near-black;
        &:hover {
          background-color: $color--near-black;
        }
      }
      .gbip-asset-preview {
        padding: 5px;
        .gbip-asset-preview__image-container {
          min-width: 100px;
          min-height: 100px;
          img {
            width: auto;
            height: auto;
          }
        }
      }
    }
  }
}
