.gbip-queue {
    height: $height--content;
    min-width: $size--content;
    // position: relative;
    display: flex;
    overflow: hidden;
    &__main,
    &__tray {
        .expandArrow {
            // width: 10px;
            // height: 10px;
            border: 2px solid $color--mid-gray;
            position: absolute;
            top: 10px;
            background-color: $color--white;
            padding: 3px;
            width: 23px;
        }
    }
    &__main {
        flex: 1 1 50%;
        height: 100%;
        position: relative;
        .gbip-queue:not(.is-tray-active) & {
            .expandArrow {
                left: 50px;
                border-left: 0px solid $color--mid-gray;
                background-color: $color--near-white;
                border-color: $color--lighter-gray;
            }
        }
        .gbip-queue:not(.is-main-active) & {
            // transform: translateX(-100%);
            display: none;
        }
        .expandArrow {
            left: 0;
            // border-left: 1px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-left: 1px solid $color--mid-gray;
        }
    }
    &__tray {
        flex: 1 1 50%;
        // position: absolute;
        top: 0;
        left: 0;
        // width: 50%;
        height: 100%;
        // z-index: 2;
        position: relative;
        .gbip-queue:not(.is-main-active) & {
            .expandArrow {
                border-right: 0px;
                right: 32px;
            }
        }
        .gbip-queue:not(.is-tray-active) & {
            // transform: translateX(-100%);
            display: none;
        }
        .gbip-queue.is-main-active & {}
        .expandArrow {
            right: 0;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-right: 1px solid $color--mid-gray;
        }
    }
    &__close-container {
        // position: absolute;
        top: 7px;
        right: 10px;
        z-index: 1;
    }
    &__close-button {
        width: 10px;
        height: 10px;
        fill: $color--dark-gray;
        &:hover,
        &:focus {
            fill: $color--near-black;
            outline: none;
        }
    }
    &__pane {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    &__section {
        flex: 0 0 auto;
        position: relative;
        &--meta {
            margin-top: 40px;
            margin-bottom: 5px;
        }
        &--view {
            display: flex;
            margin: 10px 0;
        }
        &--jobs-header {
            border: 1px solid $color--mid-gray;
            margin-bottom: 5px;
        }
        &--jobs {
            flex-grow: 1;
            margin-bottom: 20px;
        }
        &--merch {
            flex-grow: 1;
            margin-bottom: 20px;
            width: 300px;
        }
    }
    &__half {
        height: 100%;
        display: flex;
        flex-direction: column;
        &-pane {
            display: flex;
            flex-direction: column;
            &.header {
                margin-top: 45px;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                height: 40px;
                .gbip-headline-medium {
                  flex-grow: 1;
                }
                > div {
                  margin-left: 10px;
                }
            }
            &--filters {
                margin: 10px 0;
                background-color: #fff;
                .newSearch {
                    background-color: #fff;
                    display: flex;
                    align-items: stretch;
                    .queueSearch {
                        flex: 1;
                        border-radius: 0px;
                    }
                    view-options {
                        border: 1px solid $color--light-gray;
                        border-right: none;
                        .gbip-view-options {
                            display: flex;
                            justify-content: center;
                            button {
                                margin: 0px;
                                &:nth-of-type(1) {
                                    margin-left: 15px;
                                }
                                &:nth-last-child(1) {
                                    margin-right: 15px;
                                    margin-left: 7px;
                                }
                            }
                        }
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .filter-button {
                        width: 40px;
                        border: 1px solid $color--light-gray;
                        border-left: 1px solid transparent;
                        padding: 0px 10px;
                        &.open {
                            border-bottom: 1px solid white;
                        }
                    }
                    .assignFilter {
                        font-family: $ff--body;
                        font-size: $font-size--small;
                        color: $color--mid-gray;
                        width: 101px;
                        padding: 0 13px;
                        border: 1px solid $color--light-gray;
                        text-align: center;
                        &.is-active {
                            color: $color--near-black;
                            border-bottom: 2px solid $color--dark-gray;
                        }
                    }
                }
                .filters {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    justify-content: center;
                    padding-bottom: 10px;
                    border: 1px solid $color--light-gray;
                    border-top: 0px;
                    .gbip-animation-expand {
                        overflow: visible;
                    }
                    .filter {
                        flex: 0 50%;
                    }
                }
            }
            &.results {
                flex: 1 1 auto;
            }
        }
    }
}
