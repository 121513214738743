.gbip-queue-main {
    padding: 0 $size--column-gutter*2;
    // border-left: 10px solid $color--near-white;
    &--expanded {
        margin-left: 0px;
        border-left: 50px solid $color--near-white;
        padding-right: 50px;
        // margin: 0 $size--column-gutter * 2.5;
        .expandArrow {}
    }
    &__column {
        .gbip-queue-main--expanded & {
            @include flex-column(10);
            // @include flex-column(1, margin-left);
            // flex-grow: 0;
        }
    }
    &__body {
        height: $height--content;
    }
}