.modal {
    user-select: none;
    -webkit-touch-callout: none;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 99;
    display: flex;
    &[aria-hidden="true"] {
        pointer-events: none;
    }
    &__main {
        outline: none;
        margin: auto;
    }
    &__selectable {
        user-select: auto;
    }
    .close-button {
        position: absolute;
        z-index: 1;
    }
}