.outreach-title {
  text-align: center;
  margin-top: 10px;
  font-size: 21px;
  font-family: $ff--brand;
  font-weight: bold;
}

.gbip-outreach-details {
  border: 1px solid transparent;
  background-color: $color--white;
  padding: 8px 0px;
  position: relative;
  &--problem-solve {
    border-color: $color--red;
  }
}

.gbip-outreach-viewer {
  &__section {
    background-color: $color--white;
    padding: $size--column-gutter;
    position: relative;
    &-comments {
      font-family: $ff--body;
      margin: 15px 0px;
      .comment {
        margin: 15px 0px;
        .details {
          font-size: 12px;
          .name {
            font-weight: bold;
          }
        }
        .text {
          margin-top: 5px;
        }
      }
      padding-bottom: 15px;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    button {
      margin: 10px;
    }
  }
  &__header {
    display: flex;
  }
  &__headline {
    font-size: 15px;
    color: $color--near-black;
    font-family: $ff--brand;
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 10px;
  }
  &__subtitle {
    color: $color--mid-gray;
    padding-bottom: 20px;
    font-family: $ff--body;
  }
  &__bottom {
    margin: 15px 0;
    border-top: 1px solid $color--mid-gray;
  }
  &__info-panel {
    position: relative;
  }
  &__hero {
    @include ratioHeight();
    width: 100%;
  }
  &__no-image {
    @extend %gbip-headline;
    @include fullsize-child();
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color--near-white;
    padding: 20px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  &__image {
    @include absolute-position($top: 0, $left: 0);
    width: 100%;
    background-color: $color--near-white;
    .gbip-image-thumbnail & {
      @include cover();
    }
  }
  &__image--annotation {
    background-color: transparent;
  }
  &__hero-nav {
    @include vertical-center();
    width: 100%;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
  }
  &__hero-nav-item {
    width: 8px;
    margin: 0 20px;
    pointer-events: auto;
  }
  &__asset-details {
    margin-top: $size--column-gutter/2;
  }
  &__thumbnail-container {
    margin-top: $size--column-gutter;
  }
  &__thumbnail-item {
    width: 114px;
    padding: 0 $size--column-gutter/2;
  }
  .img-hero {
    display: relative;
    height: auto;
    width: 100%;
    margin: 0 auto;
    .gbip-detail-download-hero,
    .gbip-detail-close-hero {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 15px;
      right: 15px;
      margin: 0 auto;
      .gbip-icon-container {
        display: block;
        position: absolute;
        height: 0;
        right: 15px;
        top: 15px;
        width: 20px;
        padding-bottom: 20px;
      }
    }
    .gbip-detail-download-hero {
      top: 50px;
    }
    > img {
      width: 100%;
      height: auto;
    }
  }
}

.gbip-image-viewer-footer {
  margin-top: $size--column-gutter;
  padding-top: $size--column-gutter;
  border-top: 1px solid $color--mid-gray;
  display: flex;
  .gbip-image-viewer--qa & {
    justify-content: flex-end;
    align-items: baseline;
    border-top: none;
    padding-top: 0;
  }
  &__section {
    .gbip-image-viewer--qa & {
      margin-top: auto;
    }
    &--ready {
      flex: 0 0 50%;
      color: $color--dark-gray;
      font-size: $font-size--small;
      line-height: $line-height--small;
    }
    &--upload {
      margin-left: auto;
    }
    &--download-images {
      margin-left: auto;
    }
    &--download-attachments {
      margin-left: 10px;
    }
  }
}

.gbip-image-thumbnail {
  margin-bottom: $size--column-gutter;
  position: relative;
  &__wrapper {
    position: relative;
    .is-inactive & {
      @include imgDarkOverlay();
    }
    &.is-selected {
      &:after {
        content: '';
        @include absolute-position($top: 0, $left: 0);
        width: 100%;
        height: 100%;
        border: 3px solid $color--mid-gray;
        pointer-events: none;
      }
    }
  }
  &__view {
    display: flex;
    align-items: center;
    color: $color--dark-gray;
    font-size: $font-size--small;
    line-height: $line-height--small;
    margin-bottom: 1px;
    .gbip-qa-comment {
      display: inline-block;
      width: 18px;
      height: 18px;
      margin: 0 0 0 0.3em;
    }
  }
  &__library-view-code {
    color: $color--dark-gray;
    font-size: $font-size--small;
    line-height: $line-height--small;
    &__code-input {
      width: 100%;
      background-color: $color--near-white;
    }
    .library-view-code-error-group {
      display: flex;
      align-items: center;
    }
  }
  &__library-view-code-tooltip {
    position: absolute;
    right: 11px;
    width: 12px;
    height: 12px;
    top: 4px;
    fill: $color--red;
  }
  &__red {
    color: $color--red;
  }
  &__icon-group {
    width: 19px;
    height: 19px;
    @include absolute-position($top: -8px, $right: 9px);
  }
}

.gbip-qa-attachments {
  &__header {
    color: $color--dark-gray;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__delete {
    width: 8px;
    height: 8px;
    fill: $color--dark-gray;
    margin-left: 20px;
    &:hover,
    &:focus {
      fill: $color--near-black;
    }
  }
}
