progress-bar {
  width: 100%;
  .gbip-progress-container {
    width: 100%;
    .gbip-progress-bar {
      background-color: $color--green;
      transition: width 4000ms ease;
    }
  }
}

@keyframes barberpole {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 60px 30px;
  }
}
