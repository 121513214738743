.gbip-role-nav {
  position: relative;
}

  .gbip-role-links {
    @include gbip-headline($font-size--button);
    border-bottom: 1px solid $color--lighter-gray;
    display: flex;
    justify-content: center;
    width: 100%;

    &__item {
      position: relative;

      > a,
      > button {
        border-bottom: 4px solid transparent;
        display: block;
        color: $color--near-black;
        padding: 20px 12px;
        margin: 0 12px;
        text-transform: inherit;

        &.is-active {
          border-bottom-color: $color--light-gray;
        }
      }

      > a {
        &:focus {
          outline: none;
          border-bottom-color: $color--lighter-gray;
        }
      }
    }
  }