.gbip-deliver {
  .gbip-dropdown-container {
    width: 300px;
  }

  .gbip-modal__main {
    width: 650px;
  }

  .gbip-input-div {
    width: 400px;
  }

  .gbip-row {
    padding-bottom: 15px;
  }

  .gbip-queue-modal__section {
    .gbip-headline-medium {
      padding-bottom: 20px;
      text-align: left;
    }

    h2 {
      padding-top: 5px;
      text-align: left;
      margin-bottom: 15px;
    }
  }
}
